import { Component, OnInit } from '@angular/core';
import { AnalitycsService } from './core/services/analitycs.service';
import { lastValueFrom } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  constructor(
    private analyticsService: AnalitycsService,
    private router: Router
  ) {}

  ngOnInit() {
    lastValueFrom(
      this.analyticsService.logEventVisit({
        data: {
          type: 'clients',
        },
      })
    );
  }
}
