import RowTracker from '../models/rowTracker';
export type Seat = { isOccupied: boolean; prefix: string; index: number };

export class RowBitmap {
  static getSeats({ capacity, rbitmap, prefix }: RowTracker): Seat[] {
    const seats: Seat[] = Array(capacity);
    let b = rbitmap;
    for (let i = 0; i < capacity; i++, b = b >> 1) {
      const isOccupied = Boolean(b & 1);
      seats[i] = { isOccupied, prefix, index: i + 1 };
    }

    return seats;
  }

  static blockSeat(rbitmap: number, index: number) {
    const x = 1 << index;
    rbitmap = rbitmap | x;

    return rbitmap;
  }

  static freeSeat(rbitmap: number, index: number) {
    return rbitmap & ~(1 << index);
  }

  static isFree(rbitmap: number, index: number) {
    return (rbitmap & (1 << index)) === 0;
  }
}
