import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
} from '@angular/core';
import TicketType from '../../../../core/models/ticket.type';
import { TicketStatus } from '../../../../core/models/ticket.status';
import Ticket from '../../../../core/models/ticket';
import { ResponseTicketTypeOrGroupedByDate } from '../../services/ticket.service';
import { Product, ProductSeat } from 'src/app/core/types/product.type';
import { UtilService } from 'src/app/core/services/util.service';

interface ParsedTicketType extends TicketType {
  holded_tickets: Ticket[];
}

@Component({
  selector: 'event-ticket',
  templateUrl: './ticket.component.html',
  styleUrls: ['./ticket.component.css'],
})
export class TicketComponent implements OnChanges, OnDestroy {
  public groupedByDate = false;
  @Input()
  public products!: Product[];
  public productsByDay: Record<string, Product[]> = {};

  @Input()
  public eventMapImgSrc: string = '';

  @Input()
  public groupedByDateParsed: { [day: string]: ParsedTicketType[] } = {};
  public days: string[] = [];
  parsedTicketTypeList: ParsedTicketType[] = [];

  @Output()
  onTicketChangeEvent = new EventEmitter<TicketType>();
  @Output()
  onAddSeatEvent = new EventEmitter<ProductSeat>();
  @Output()
  onRemoveSeatEvent = new EventEmitter<ProductSeat>();

  @Output()
  onProductChangeEvent = new EventEmitter<Product>();

  ticketsUsed = 0;

  @Input()
  public ticketTypeList: ResponseTicketTypeOrGroupedByDate = [];

  public ticketTypesLefts: Record<number, number> = {};
  private isListenersSet = false;
  private inSessionCount: Record<number, number> = {};

  constructor(private utilService: UtilService) {}

  ngOnDestroy(): void {
    this.removeListenersTicketTypesListeners();
  }

  ngOnChanges() {
    if (this.isTicketTypeListEmpty()) return;

    if (!this.isListenersSet) {
      this.setListenersTicketTypesListeners();
      this.isListenersSet = true;
    }

    this.setProducts();

    this.days = Object.keys(this.ticketTypeList);
  }

  getGroupedByDayProductIndex(day: string, offset: number) {
    if (Array.isArray(this.ticketTypeList)) return -1;

    let index = 0;
    for (const curDay of Object.keys(this.ticketTypeList)) {
      if (curDay === day) {
        return index + offset;
      }

      index += this.ticketTypeList[curDay].length - 1;
    }

    return index;
  }

  getTicketTypeList() {
    if (this.isGroupedByDate()) return [];
    return Object.values(this.ticketTypeList);
  }

  getGroupedByDayData(day: string) {
    const data = this.ticketTypeList;

    if (Array.isArray(data)) {
      return [];
    }

    return data[day];
  }

  /**
   * Returns true if the [ticketType] param is NOT sold and is of type seat
   * @param ticketType
   * @return boolean
   */
  isSeat(ticketType: TicketType): boolean {
    return !this.isUnavailable(ticketType) && ticketType.isSeat;
  }

  /**
   * Returns true if the [ticketType] param is NOT sold and is of type selector
   * @param ticketType
   * @return boolean
   */
  isSelector(ticketType: TicketType): boolean {
    return !this.isUnavailable(ticketType) && !ticketType.isSeat;
  }

  /**
   * Returns true if the [ticketType] param is sold
   * @param ticketType
   * @return boolean
   */
  isUnavailable(ticketType: TicketType) {
    const soldOut = this.isSoldOut(ticketType);
    const holdedOut = this.isHoldedOut(ticketType);

    return soldOut || holdedOut;
  }

  isSoldOut(ticketType: TicketType) {
    return ticketType.lefts <= 0;
  }

  // TODO: Implement this
  isHoldedOut(ticketType: TicketType) {
    return this.isSoldOut(ticketType) && this.inSessionCount[ticketType.id] > 0;
  }

  sumUsed() {
    this.ticketsUsed += 1;
  }

  substractUsed() {
    this.ticketsUsed -= 1;
  }

  isGroupedByDate() {
    return !Array.isArray(this.ticketTypeList);
  }

  protected readonly TicketStatus = TicketStatus;

  private setProducts() {
    this.products = [];
    this.productsByDay = {};
    if (Array.isArray(this.ticketTypeList)) {
      this.ticketTypeList.forEach((tt: TicketType) => {
        this.products.push({
          ticket_type_id: tt.id,
          qty: 0,
          seats: [],
        });

        this.ticketTypesLefts[tt.id] = tt.lefts;
      });
    } else {
      for (const day of Object.keys(this.ticketTypeList)) {
        this.productsByDay[day] = this.ticketTypeList[day].map((tt) => {
          this.ticketTypesLefts[tt.id] = tt.lefts;
          return {
            ticket_type_id: tt.id,
            qty: 0,
            seats: [],
          };
        });
      }
    }
  }

  private setListenersTicketTypesListeners() {
    Object.values(this.ticketTypeList)
      .flat()
      .forEach((ticketType: TicketType) => {
        const inSessionCountKeyListener = this.getInSessionCountKeyListener(
          ticketType.id
        );
        const ticketTypeLeftsKeyListener = this.getTicketTypeLeftsKeyListener(
          ticketType.id
        );

        this.utilService
          .getSocket()
          .on(inSessionCountKeyListener, (count: number) => {
            this.inSessionCount[ticketType.id] = count;
          });

        this.utilService
          .getSocket()
          .on(ticketTypeLeftsKeyListener, (count: number) => {
            ticketType!.lefts = count;
            this.ticketTypesLefts[ticketType.id] = count;
          });
      });
  }

  private removeListenersTicketTypesListeners() {
    Object.values(this.ticketTypeList)
      .flat()
      .forEach((ticketType) => {
        const inSessionCountKeyListener = this.getInSessionCountKeyListener(
          ticketType.id
        );
        const ticketTypeLeftsKeyListener = this.getTicketTypeLeftsKeyListener(
          ticketType.id
        );
        this.utilService
          .getSocket()
          .removeAllListeners(inSessionCountKeyListener);

        this.utilService
          .getSocket()
          .removeAllListeners(ticketTypeLeftsKeyListener);
      });
  }

  private isTicketTypeListEmpty() {
    if (Array.isArray(this.ticketTypeList)) {
      return this.ticketTypeList.length === 0;
    } else {
      return Object.values(this.ticketTypeList).flat().length === 0;
    }
  }

  private getInSessionCountKeyListener(ticketTypeId: number) {
    return `session_${ticketTypeId}_notification_count`;
  }

  private getTicketTypeLeftsKeyListener(ticketTypeId: number) {
    return `ticket_type_${ticketTypeId}_notification_lefts`;
  }
}
