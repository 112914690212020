import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import OrderDetails, {
  TicketData,
} from '../../../../core/models/order.details';
import { OrderTicketData } from '../order-ticket/order-ticket.component';
import { TicketStatus } from '../../../../core/models/ticket.status';
// @ts-ignore
import * as html2pdf from 'html2pdf.js';

@Component({
  selector: 'order-detail-tickets',
  templateUrl: './order-detail-tickets.component.html',
  styleUrls: ['./order-detail-tickets.component.css'],
})
export class OrderDetailTicketsComponent {
  @ViewChild('ticketPrint') ticketPrint!: ElementRef;

  @Input() order: OrderDetails | null = null;
  public ticketData: OrderTicketData | null = null;
  isLoading: any;

  get orderCancelled() {
    return this.order?.status == TicketStatus.CANCELLED;
  }

  get tickets(): any[] {
    if (!this.order) return [];

    return this.order.tickets;
  }

  getTitle(): string {
    const size = this.tickets.length ?? 0;
    const label = size > 1 ? 'Tickets' : 'Ticket';
    return `(${size}) ${label} en tu orden`;
  }

  getGiftPicture(name: string) {
    if (name.toLowerCase() === 'brugal') {
      return '../../../../../assets/img/traguito_horizontal.png';
    }

    return '../../../../../assets/img/bocaito_horizontal.png';
  }

  onClickSeeQR(ticket: TicketData): void {
    this.ticketData = {
      eventDate: this.order?.event?.startAt ?? new Date(),
      eventName: this.order?.event?.name ?? '',
      buyerName: this.order?.buyerName ?? '',
      buyerEmail: this.order?.buyerEmail ?? '',
      eventPlace: this.order?.event?.place ?? '',
      orderId: this.order?.id ?? 0,
      printingDate: null,
      ticketId: ticket.id,
      ticketStatus: ticket.checkInDate === null ? 'Disponible' : 'Usado',
      ticketLabel: ticket.isSeat ? ticket.label : undefined,
      ticketType: {
        data: { id: ticket.ticketType.id, attributes: ticket.ticketType },
      },
      qrSource: ticket.qrcode,
    };
  }
  async downloadTicket() {
    try {
      let ticket: HTMLElement = this.ticketPrint.nativeElement;

      html2pdf()
        .set({
          filename: `Ticket ${this.ticketData?.ticketId}`,
          image: {
            quality: 1,
            type: 'webp',
          },
          html2canvas: {
            allowTaint: true,
            useCORS: true,
            scale: 1.5,
          },
        })
        .from(ticket)
        .toContainer()
        .toCanvas()
        .toPdf()
        .save();
    } catch (e) {
      console.log(e);
    }
  }
}
