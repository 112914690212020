import { Component, Input, OnInit } from '@angular/core';
import EventModel from '../../../../../../core/models/event';
import { Constants } from '../../../../../utils/constants';
import { StorageService } from '../../../../../services/storage.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-event-item',
  templateUrl: './event-item.component.html',
  styleUrls: ['./event-item.component.css'],
})
export class EventItemComponent implements OnInit {
  @Input() event: EventModel | undefined;

  constructor(
    private localStorageService: StorageService,
    private router: Router
  ) {}

  ngOnInit(): void {}

  goToEvent() {
    this.router.navigate([`event/${this.event?.slug}`]);
  }
}
