import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import Ticket from '../core/models/ticket';
import Order from '../core/models/order';
import { UtilService } from '../core/services/util.service';
import { CybersourceThreeDSStatus } from '../core/enums/cybersourceThreeDSStatus.enum';

type DeviceDataParams = {
  accessToken: string;
  referenceId: string;
  deviceDataCollectionUrl: string;
};

type DeviceDataResponse = {
  MessageType: string;
  SessionId: string;
  Status: boolean;
  payment_history_id: number;
};

type WaitValidationResultResponse =
  | Order
  | {
      status: CybersourceThreeDSStatus;
      errorInformation: { reason: CybersourceThreeDSStatus; message: string };
      clientReferenceInformation: { code: string };
      processorInformation: { responseCode: string };
    };

type HandleThreeSessionParam = {
  ddp: DeviceDataParams;
  paymentInfo: any;
  paymentHistoryId: number;
  order: any;
  onGettingResult(): void;
};

type HandleThreeSessionResponse = {
  orderId: number;
  slug: string;
  createdAt: Date;
};

type ValidatePayerAuthenticationResponse =
  | CheckPayerAuthenticationCybersourceResponse
  | Order;

type CheckPayerAuthenticationCybersourceResponse = {
  id: string;
  submitTimeUtc: string;
  status: CybersourceThreeDSStatus;
  errorInformation: { reason: CybersourceThreeDSStatus; message: string };
  consumerAuthenticationInformation: ConsumerAuthenticationInformation;
};

export type ErrorInformation = {
  reason: string;
  message: string;
  code: string;
};

type ConsumerAuthenticationInformation = {
  accessToken: string;
  acsTransactionId: string;
  acsUrl: string;
  authenticationTransactionId: string;
  challengeRequired: string;
  pareq: string;
  specificationVersion: string;
  stepUpUrl: string;
  threeDSServerTransactionId: string;
  veresEnrolled: string;
  directoryServerTransactionId: string;
  acsOperatorID: string;
  acsReferenceNumber: string;
};

const TEN_SECONDS = 10 * 1000;
const CARDINAL_COLLECTION_FORM_ID = '#cardinal_collection_form';
const CARDINAL_COLLECTION_INPUT_ID = '#cardinal_collection_form_input';
const STEP_UP_FORM_ID = '#step-up-form';
const STEP_UP_FORM_INPUT_ID = '#step-up-form-input';
const STEP_UP_FORM_INPUT_REFERENCE_ID = '#step-up-form-input-reference-id';
const STEP_UP_IFRAME_CONTAINER_ID = '#step-up-iframe-position';
const PURCHASE_AUTHENTICATION_BTN_ID = '#purchaseAuthenticationBtn';

@Injectable({
  providedIn: 'root',
})
export class ThreeDSCybersourceService {
  constructor(
    private readonly http: HttpClient,
    private readonly utilService: UtilService
  ) {}

  async handleThreeDSSession({
    ddp,
    paymentHistoryId,
    order,
    paymentInfo,
    onGettingResult,
  }: HandleThreeSessionParam): Promise<
    HandleThreeSessionResponse | ErrorInformation | null
  > {
    const deviceData = await this.collectDeviceData(ddp);

    if (!deviceData?.Status) {
      return null;
    }

    const postData = this.buildOrderData(
      paymentInfo,
      order,
      paymentHistoryId,
      ddp.referenceId
    );

    let result: Order | null = null;

    while (!result) {
      const validationResult = await this.checkAndValidateAndResultValidation(
        postData,
        ddp,
        onGettingResult
      );

      if (!validationResult) {
        return null;
      }

      if ('orderId' in validationResult) {
        return validationResult;
      }

      const isOrder = 'slug' in validationResult;

      if (isOrder) {
        result = validationResult;
      }

      if (
        !isOrder &&
        'errorInformation' in validationResult &&
        'processorInformation' in validationResult
      ) {
        return {
          code: validationResult.processorInformation.responseCode,
          reason: validationResult.errorInformation.reason,
          message: validationResult.errorInformation.message,
        };
      }

      // if (
      //   !isOrder &&
      //   this.isPaymentError(validationResult?.errorInformation?.reason)
      // ) {
      //   return validationResult.errorInformation;
      // }

      // if (!isOrder && this.isPaymentError(validationResult.status)) {
      //   return validationResult.status as CybersourceThreeDSStatus;
      // }

      if (
        !isOrder &&
        validationResult.status !== CybersourceThreeDSStatus.PENDING
      ) {
        return null;
      }

      this.removeStepUpIframe();
    }

    return {
      orderId: result.id,
      createdAt: result.createdAt as Date,
      slug: result.slug,
    };
  }

  async checkAndValidateAndResultValidation(
    postData: any,
    ddp: DeviceDataParams,
    onGettingResult: () => void
  ) {
    const vpa = await this.validatePayerAuthentication(postData);

    if ('slug' in vpa) {
      return {
        orderId: vpa.id,
        createdAt: vpa.createdAt as Date,
        slug: vpa.slug,
      };
    }

    if (
      vpa.status === CybersourceThreeDSStatus.FAILED ||
      vpa.status === CybersourceThreeDSStatus.INVALID
    ) {
      return null;
    }

    const accessToken = (vpa as any).consumerAuthenticationInformation
      ?.accessToken;
    const stepUpUrl = (vpa as any).consumerAuthenticationInformation?.stepUpUrl;

    if (!accessToken && 'errorInformation' in vpa) {
      return vpa;
    }

    this.renderStepUpIframe(accessToken, stepUpUrl, ddp.referenceId);

    const validationResult = await this.waitValidationResult(
      ddp.referenceId,
      postData,
      onGettingResult
    );

    return validationResult;
  }

  async waitValidationResult(
    referenceId: string,
    postData: any,
    onGettingResult: () => void
  ): Promise<WaitValidationResultResponse> {
    const closeBtn = document.querySelector<HTMLButtonElement>(
      '#purchaseAuthenticationBtnClose'
    );
    return new Promise((resolve, reject) => {
      onGettingResult();

      this.utilService.getSocket().on(referenceId, async (data) => {
        try {
          closeBtn?.click();
          this.removeStepUpIframe();
          const result = await this.validatePayerAuthenticationResult(
            data,
            postData
          );

          resolve(result as any);
        } catch (error) {
          reject(error);
        }
      });
    });
  }

  async validatePayerAuthenticationResult(
    transactionId: string,
    postData: any
  ) {
    postData.data.transactionId = transactionId;
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };

    const result = await this.getAuthenticationResultValidation(
      options,
      postData
    );
    return result;
  }

  async collectDeviceData({
    accessToken,
    deviceDataCollectionUrl,
  }: DeviceDataParams) {
    const cardinalCollectionForm = document.querySelector<HTMLFormElement>(
      CARDINAL_COLLECTION_FORM_ID
    )!;
    const cardinalCollectionInput = document.querySelector<HTMLInputElement>(
      CARDINAL_COLLECTION_INPUT_ID
    )!;

    cardinalCollectionForm.action = deviceDataCollectionUrl;
    cardinalCollectionInput.value = accessToken;

    const data = await this.getDeviceData(cardinalCollectionForm);
    return data;
  }

  private buildOrderData(
    paymentInfo: any,
    order: any,
    paymentHistoryId: number,
    referenceId: string
  ) {
    const tickets: Ticket[] = [];

    order.tickets.forEach((ticketType: any) => {
      tickets.push(...ticketType.tickets);
    });

    const postData: any = {
      data: {
        paymentHistoryId,
        referenceId,
        orderInfo: {
          event: order.event,
          tickets: tickets,
          coupons: order.coupons,
          buyer_full_name: order.buyerName,
          buyer_email: order.buyerEmail,
          status: 'active',
          insurance_fee: order.insuranceFee,
          service_fee: order.serviceFee,
          total: order.totalToPay,
          sub_total: order.subTotal,
          discounted: order.discounted,
          ticketIds: order.ticketIds,
          event_promoter: order.promoter,
          free_event: order.free_event,
        },
        paymentInfo: {
          ...paymentInfo,
        },
      },
    };

    return postData;
  }

  private async validatePayerAuthentication(
    postData: any
  ): Promise<ValidatePayerAuthenticationResponse> {
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };

    const vpaData: any = await this.get(
      options,
      postData,
      'checkPayerAuthenticationEnrollment'
    );

    return vpaData;
  }

  private renderStepUpIframe(
    accessToken: string,
    stepUpURL: string,
    referenceId: string
  ) {
    const stepUpform =
      document.querySelector<HTMLFormElement>(STEP_UP_FORM_ID)!;
    const stepUpformInput = document.querySelector<HTMLInputElement>(
      STEP_UP_FORM_INPUT_ID
    )!;
    const stepUpformInputReference = document.querySelector<HTMLInputElement>(
      STEP_UP_FORM_INPUT_REFERENCE_ID
    )!;
    const stepUpIframeContainer = document.querySelector<HTMLInputElement>(
      STEP_UP_IFRAME_CONTAINER_ID
    )!;
    const btn = document.querySelector<HTMLButtonElement>(
      PURCHASE_AUTHENTICATION_BTN_ID
    );

    const iframe = document.createElement('iframe');
    iframe.style.width = '400px';
    iframe.style.height = '750px';

    iframe.name = 'step-up-iframe';
    iframe.id = 'step-up-iframe';

    stepUpIframeContainer.appendChild(iframe);

    stepUpform.action = stepUpURL;
    stepUpformInput.value = accessToken;
    stepUpformInputReference.value = referenceId;

    stepUpform.submit();

    btn?.click();
  }

  private removeStepUpIframe() {
    const iframe = document.querySelector<HTMLInputElement>('#step-up-iframe')!;
    if (iframe) {
      iframe.remove();
    }
  }

  private async getAuthenticationResultValidation(
    options: { headers: HttpHeaders },
    data: any
  ) {
    return this.get(options, data, 'validateAuthenticationResult');
  }

  private getDeviceData(form: HTMLFormElement) {
    return new Promise<DeviceDataResponse | null>((resolve, reject) => {
      form.submit();
      window.addEventListener('message', (event) => {
        if (event.origin === environment.apiCardinal) {
          resolve(JSON.parse(event.data) as DeviceDataResponse);
        }
      });

      setTimeout(() => {
        resolve(null);
      }, TEN_SECONDS);
    });
  }

  private async get(
    options: { headers: HttpHeaders },
    data: any,
    cybersourcePath: string
  ) {
    return new Promise((resolve, reject) => {
      this.http
        .post(
          `${environment.apiUrl}/cybersource/${cybersourcePath}`,
          JSON.stringify(data),
          options
        )
        .subscribe({
          next: (resp) => resolve(resp),
          error: (err) => reject(err),
        });
    });
  }
}
