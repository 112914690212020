import { Component, Input, OnInit } from '@angular/core';
import TicketType from '../../../../core/models/ticket.type';
import { HttpClient } from '@angular/common/http';

export interface OrderTicketData {
  orderId: number;
  ticketId: number;
  ticketStatus: string;
  ticketLabel: string | undefined;
  ticketType: { data: { attributes: TicketType; id: number } };
  printingDate: Date | null;
  eventName: string;
  buyerName: string;
  eventPlace: string;
  eventDate: Date;
  buyerEmail: string;
  qrSource?: string;
}

@Component({
  selector: 'order-ticket',
  templateUrl: './order-ticket.component.html',
  styleUrls: ['./order-ticket.component.css'],
})
export class OrderTicketComponent implements OnInit {
  @Input() public data: any | null = null;
  ticketsHeader: string = '';

  qrSource = '';

  get place() {
    const result = this.data?.ticketType.place
      ? this.data?.ticketType.place
      : this.data?.eventPlace;

    return result;
  }

  get date() {
    const result = this.data?.ticketType.start_on
      ? this.data?.ticketType.start_on
      : this.data?.eventDate;

    return result;
  }

  constructor(private http: HttpClient) {}

  ngOnInit() {
    this.http
      .get('/assets/img/logo-tix-svg.svg', { responseType: 'blob' })
      .subscribe((res) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          var base64data = reader.result;
          this.ticketsHeader = base64data as string;
        };

        reader.readAsDataURL(res);
      });
  }
}
