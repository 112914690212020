import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import TicketType from '../../../../../core/models/ticket.type';
import { Product } from 'src/app/core/types/product.type';

@Component({
  selector: 'selector-button',
  templateUrl: './selector-button.component.html',
  styleUrls: ['./selector-button.component.css'],
})
export class SelectorButtonComponent implements OnInit, OnChanges {
  @Input() ticketType: TicketType | null = null;
  @Input() product!: Product;
  @Input()
  ticketTypeLefts = 0;
  @Output()
  productEventEmitter = new EventEmitter<Product>();

  ngOnInit(): void {
    if (!this.ticketType) return;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['ticketTypeLefts']) {
      this.ticketTypeLefts = changes['ticketTypeLefts'].currentValue;

      if (this.ticketTypeLefts < this.product.qty) {
        alert(
          `No es posible solicitar esta cantidad porque hay menos disponibles (${this.ticketTypeLefts}).`
        );

        this.product.qty -= this.ticketTypeLefts;
      }
    }
  }

  isTicketLimitReached(): boolean {
    if (this.ticketType) {
      return this.ticketTypeLefts - this.product.qty <= 0;
    }
    return true;
  }

  incrementQty() {
    if (!this.ticketType) return;
    const { max_tickets_per_order, min_tickets_per_order } = this.ticketType;

    if (this.product.qty < min_tickets_per_order) {
      this.product.qty = min_tickets_per_order;
      this.productEventEmitter.emit(this.product);
      return;
    }

    const newQty = this.product.qty + 1;
    if (newQty > this.ticketType.max_tickets_per_order) {
      alert('Tickets máximos por orden: ' + max_tickets_per_order);
    } else {
      this.product.qty = newQty;
      this.productEventEmitter.emit(this.product);
    }
  }

  decrementQty(by?: number) {
    const minPerOrder = this.ticketType?.min_tickets_per_order ?? 1;
    if (this.product.qty === minPerOrder) {
      this.product.qty = 0;
      this.productEventEmitter.emit(this.product);
      return;
    }

    this.product.qty -= by ?? 1;
    this.productEventEmitter.emit(this.product);
  }
}
