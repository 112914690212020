<main class="justify-content-between align-items-center" id="page_confirmation">
  <div class="container-fluid" style="max-width: 660px">
    <div class="card padding-15">
      <div class="row justify-content-center g-4">
        <div class="col-12 col-md-10">
          <header class="pt-4 pb-2 d-flex align-items-center">
            <figure class="m-0 me-3">
              <img src="assets/img/logo-tix.png" alt="" width="54" />
            </figure>
          </header>
        </div>
      </div>

      <div class="row justify-content-center">
        <div class="col-12 col-md-10">
          <div class="row justify-content-between align-items-center g-3">
            <div class="col-12 text-center">
              <span
                class="material-symbols-outlined text-success"
                style="font-size: 130px"
                >check_circle</span
              >
              <h1 class="h3">Gracias por tu orden</h1>
            </div>

            <div class="col">
              <ul class="list-group">
                <li
                  class="list-group-item p-0 border-0 bg-transparent text-uppercase"
                >
                  <div class="h5">Order TIX-{{ order?.id }}</div>
                </li>
                <li
                  class="list-group-item p-0 border-0 bg-transparent text-muted"
                >
                  {{ order?.buyerName }}
                </li>
                <li
                  class="list-group-item p-0 border-0 bg-transparent text-muted"
                >
                  {{ order?.buyerEmail }}
                </li>
                <li
                  class="list-group-item p-0 border-0 bg-transparent text-muted"
                >
                  {{ order?.createdAt | dateFormat }}
                </li>
              </ul>
            </div>

            <div class="col-12">
              <div class="d-flex justify-content-between align-items-center">
                <a
                  [target]="'_blank'"
                  routerLink="/order/{{ order?.slug }}/details"
                  class="w-100 btn btn-dark rounded-pill px-4"
                  >Ver tickets</a
                >
              </div>
            </div>

            <div class="col-12">
              <hr class="p-0" />
            </div>
          </div>
        </div>
      </div>

      <div class="row justify-content-center">
        <div class="col-12 col-md-10">
          <article>
            <div class="card bg-transparent border-0 py-3">
              <div class="row gx-4 gy-2 align-items-center">
                <div class="col-md-auto">
                  <img
                    [src]="getImageUrl()"
                    class="img-fluid rounded-start rounded-tix"
                    alt=""
                    width="80"
                  />
                </div>
                <div class="col-md-auto">
                  <div class="card-body p-0">
                    <h5 class="card-title mb-1">{{ eventName }}</h5>
                    <p class="m-0 card-text" *ngFor="let date of dates">
                      {{ date | dateFormat }}
                    </p>
                    <p class="card-text m-0">{{ eventPlace }}</p>
                  </div>
                </div>
              </div>
            </div>
          </article>
        </div>

        <div class="col-12 col-md-10">
          <div class="tickets">
            <ul class="list-group gap-3">
              <li
                class="list-group-item bg-transparent px-0 border-0 rounded-0"
                *ngFor="let ticketType of parsedTicketsData"
              >
                <div
                  class="event-ticket-type d-flex align-items-start justify-content-between"
                >
                  <div class="event-ticket-type ticket-type-information">
                    <h2 class="h6 mb-1 fw-bold text-uppercase">
                      {{ ticketType.ticketTypeName }}
                    </h2>
                    <data class="amount d-block small" value="1200.00"
                      >{{
                        order?.free_event
                          ? ''
                          : 'RD$' + (ticketType.price | number)
                      }}
                      X {{ ticketType.qty }}</data
                    >
                    <div *ngIf="ticketType.isSeat">
                      <small class="text-muted">Asientos alegidos</small>
                      <ul
                        class="list-group border-start rounded-0 mx-1 mt-2 gap-2"
                      >
                        <li
                          class="list-group-item p-0 bg-transparent border-0 d-flex ps-2"
                          *ngFor="let ticketLabel of ticketType.ticketsLabel"
                        >
                          <span
                            class="material-symbols-outlined mx-1"
                            style="font-size: 22px"
                            >event_seat</span
                          >
                          <small class="text-muted">{{ ticketLabel }}</small>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <hr />
        </div>

        <div class="col-12 col-md-10" *ngIf="codeCoupons.length">
          <form action="" class="position-relative">
            <div class="form-floating small" *ngFor="let coupon of codeCoupons">
              <input
                type="text"
                class="form-control border-secondary px-3 rounded-input"
                id="nameInput"
                placeholder="random"
                disabled
              />
              <label for="nameInput" class="fw-light px-3"
                >Código de descuento: {{ coupon.code }}</label
              >
            </div>
          </form>
          <hr />
        </div>

        <div class="col-12 col-md-10" *ngIf="!order?.free_event">
          <ul class="list-group">
            <li
              class="list-group-item border-0 px-0 bg-transparent justify-content-between d-flex w-100"
            >
              <span>Subtotal</span>
              <span class="fw-bold">RD${{ order?.subTotal | number }}</span>
            </li>
            <li
              class="list-group-item border-0 px-0 bg-transparent justify-content-between d-flex w-100"
            >
              <span>Cargo por servicio</span>
              <span class="fw-bold">RD${{ order?.serviceFee | number }}</span>
            </li>
            <li
              class="list-group-item border-0 px-0 bg-transparent justify-content-between d-flex w-100"
            >
              <span>Cargo por seguro</span>
              <span class="fw-bold">RD${{ order?.insuranceFee | number }}</span>
            </li>
            <li
              *ngIf="cardCoupon"
              class="list-group-item border-0 px-0 bg-transparent justify-content-between d-flex w-100"
            >
              <div class="d-flex align-items-center gap-1">
                <span>Descuento ({{ this.cardCoupon?.name }}</span>
                <span> -{{ this.cardCoupon?.discount }}%)</span>
              </div>
              <span class="fw-bold coupon-amount"
                >-RD${{ this.cardCoupon?.discounted | number }}</span
              >
            </li>
            <li
              class="list-group-item border-0 px-0 bg-transparent justify-content-between d-flex w-100"
              *ngIf="codeCoupons.length"
            >
              <span>Cupones ({{ couponsNames }}) aplicados</span>
              <span class="fw-bold coupon-amount"
                >-RD${{ this.order?.discounted | number }}</span
              >
            </li>
            <hr />
            <li
              class="list-group-item border-0 px-0 align-items-center bg-transparent justify-content-between d-flex w-100"
            >
              <span class="fw-bold h5 m-0 text-uppercase">Total</span>
              <span class="fw-bold h5 m-0"
                >RD${{ order?.totalToPay | number }}</span
              >
            </li>
          </ul>
        </div>
      </div>

      <div class="row justify-content-center text-center">
        <div class="col-10">
          <div class="py-4">Confirmación de orden enviada al correo</div>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid" style="max-width: 660px">
    <div class="row justify-content-center text-center">
      <div class="col-10">
        <div class="py-4">
          <a
            href="/events/all"
            class="btn btn-light bg-transparent px-1 rounded-pill border-0"
            >Volver a tix.do</a
          >
        </div>
      </div>
    </div>
  </div>
</main>
