import { Component, OnInit } from '@angular/core';
import { StorageService } from '../../../../core/services/storage.service';
import Order from '../../../../core/models/order';
import { Constants } from '../../../../core/utils/constants';
import { UtilService } from '../../../../core/services/util.service';
import { OrderService } from '../../../order/services/order.service';
import CouponType from '../../../../core/enums/couponType.enum';
import { Cover, TicketData } from 'src/app/core/models/order.details';
import { tick } from '@angular/core/testing';
import {
  ExplicitCouponsType,
  ExplicitCouponType,
} from '../checkout/checkout.component';

type ParsedTicketData = {
  ticketTypeId: number;
  qty: number;
  ticketTypeName: string;
  ticketsLabel: string[];
  price: number;
  isSeat: boolean;
};

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.css'],
})
export class ConfirmationComponent implements OnInit {
  public order: Order | null = null;
  public parsedTicketsData: ParsedTicketData[] = [];
  public dates: string[] = [];
  public eventName: string = '';
  public eventPlace: string = '';

  public codeCoupons: ExplicitCouponsType<CouponType.Code> = [];
  public cardCoupon?: ExplicitCouponType<CouponType.CreditCard>;

  private eventCover: string = '';

  get discounted() {
    return this.orderService.codeCouponsDiscount(this.order);
  }

  get couponsNames() {
    return this.codeCoupons.map((c) => c.code);
  }

  constructor(
    private localStorageService: StorageService,
    private utilService: UtilService,
    private orderService: OrderService
  ) {}

  ngOnInit(): void {
    this.order =
      JSON.parse(
        this.localStorageService.get(Constants.Keys.OrderConfirmation) ?? ''
      ) ?? null;
    if (!this.order?.slug) return;
    this.initOrder(this.order?.slug);
  }

  getImageUrl() {
    return this.utilService.getAbsoluteUrl(this.eventCover);
  }

  private async initOrder(slug: string) {
    this.orderService.getDetail(slug).subscribe((resp) => {
      if (!resp) return;
      this.order = resp as unknown as Order;
      this.parsedTicketsData = this.getParsedTicketsData(resp?.tickets);
      this.eventCover = this.getCover(resp?.event?.cover);
      this.eventName = resp?.event.name || '';
      this.eventPlace = resp?.event.place || '';
      resp?.discounts.forEach((discount) => {
        const coupon = discount.coupon;

        switch (coupon.type) {
          case CouponType.Code:
            this.codeCoupons.push(
              coupon as ExplicitCouponType<CouponType.Code>
            );
            break;
          case CouponType.CreditCard:
            this.cardCoupon =
              coupon as ExplicitCouponType<CouponType.CreditCard>;
            this.cardCoupon.discounted =
              (this.order?.subTotal || 0) * (this.cardCoupon.discount / 100);
            break;
        }
      });
      this.setStartDates(resp?.tickets);
    });
  }

  private setStartDates(tickets?: TicketData[]) {
    if (!tickets) return;
    tickets.forEach((ticket) => {
      const tt = ticket.ticketType;

      if (tt.start_on && !this.dates.includes(tt.start_on)) {
        this.dates.push(tt.start_on);
      }

      this.dates = this.dates.sort();

      if (this.dates.length === 0 && this.order?.event) {
        this.dates.push(this.order.event.startAt as any);
      }
    });
  }

  private getCover(covers?: Cover[]) {
    if (!covers || covers?.length === 0) return '';

    for (const cover of covers) {
      if (!cover.name.startsWith('tix_main_cover')) continue;
      return cover.url;
    }
    return covers[0].url;
  }

  private getParsedTicketsData(tickets?: TicketData[]) {
    if (!tickets) {
      return [];
    }

    const data: ParsedTicketData[] = [];

    tickets.forEach((ticket) => {
      const ticketTypeId = ticket.ticketType.id;
      let index = data.findIndex((t) => t.ticketTypeId === ticketTypeId);
      if (index < 0) {
        data.push({
          qty: 0,
          price: ticket.ticketType.price,
          ticketTypeId: ticketTypeId,
          ticketTypeName: ticket.ticketType.name,
          isSeat: ticket.ticketType.isSeat,
          ticketsLabel: [],
        });

        index = data.length - 1;
      }

      data[index].qty++;
      data[index].ticketsLabel.push(ticket.label);
    });
    return data;
  }
}
