import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map, Observable, of } from 'rxjs';
import { environment } from '../../../../environments/environment';
import Event from '../../../core/models/event';
import Order from '../../../core/models/order';
import Ticket from '../../../core/models/ticket';
import PaymentInfo from 'src/app/core/models/payment.info';
import { UtilService } from '../../../core/services/util.service';
import { TicketService } from './ticket.service';
import * as moment from 'moment';
import { StorageService } from 'src/app/core/services/storage.service';
import { Constants } from 'src/app/core/utils/constants';
import { OrderPrices } from 'src/app/core/types/OrderPrices.type';
import { Product } from 'src/app/core/types/product.type';

export interface OrderFee {
  serviceFee: 0;
  insuranceFee: 0;
  gateway: string;
}
@Injectable({
  providedIn: 'root',
})
export class EventService {
  constructor(
    private http: HttpClient,
    private utilService: UtilService,
    private localStorage: StorageService,
    public ticketService: TicketService
  ) {}

  getEvents(slug?: string): Observable<Event[]> {
    const currentDate = moment();
    const currentDateString = currentDate.toISOString();
    const currenDateThirtyMinus = currentDate.subtract(30, 'minutes');

    let filterBySlug = slug ? `&filters[slug]=${slug}` : '';
    return this.http
      .get(
        `${
          environment.apiUrl
        }/events?filters[status]=active&filters[end_at][$gte]=${currenDateThirtyMinus.toISOString()}&sort[0]=start_at${filterBySlug}&filters[available_at][$lte]=${currentDateString}&pagination[pageSize]=100`
      )
      .pipe(
        map((resp: any) => {
          const mappedResp = this._mapEvent(resp);
          this.localStorage.set(
            Constants.Keys.Event,
            JSON.stringify(mappedResp)
          );
          return mappedResp;
        }),
        catchError((err) => {
          console.log('error', err);
          const events = this.localStorage.get(Constants.Keys.Event);
          if (events) {
            return of(JSON.parse(events));
          }
          return of([]);
        })
      );
  }

  getOrderPrices(products: Product[], discountTokens: string[]) {
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };

    return this.http
      .post<OrderPrices>(
        `${environment.apiUrl}/event-setting/calcTicketsPrice`,
        { products, discount_tokens: discountTokens },
        options
      )
      .pipe(
        map((resp: OrderPrices) => {
          return resp;
        }),
        catchError(() => of(null))
      );
  }

  getDashboardEvents(): Observable<Event[]> {
    const currentDate = moment();
    const currentDateString = currentDate.toISOString();
    const currenDateThirtyMinus = currentDate.subtract(30, 'minutes');

    return this.http
      .get(
        `${
          environment.apiUrl
        }/events/dashboard/events?filters[end_at]=${currenDateThirtyMinus.toISOString()}&filters[available_at]=${currentDateString}&pagination[pageSize]=100`
      )
      .pipe(
        map((resp: any) => {
          const mappedResp = this._mapEvent(resp);
          this.localStorage.set(
            Constants.Keys.Event,
            JSON.stringify(mappedResp)
          );
          return mappedResp;
        }),
        catchError((err) => {
          console.log('error', err);
          const events = this.localStorage.get(Constants.Keys.Event);
          if (events) {
            return of(JSON.parse(events));
          }
          return of([]);
        })
      );
  }

  checkout(order: Order, paymentInfo: PaymentInfo): Observable<any> {
    const tickets: Ticket[] = [];

    order.tickets.forEach((ticketType) => {
      tickets.push(...ticketType.tickets);
    });

    //const cardExpDate = paymentInfo.expDate.toString().replace('-', '');

    //DTO
    const postData: any = {
      data: {
        orderInfo: {
          event: order.event,
          tickets: tickets,
          coupons: order.coupons,
          buyer_full_name: order.buyerName,
          buyer_email: order.buyerEmail,
          status: 'active',
          insurance_fee: order.insuranceFee,
          service_fee: order.serviceFee,
          total: order.totalToPay,
          sub_total: order.subTotal,
          discounted: order.discounted,
          ticketIds: order.ticketIds,
          event_promoter: order.promoter,
          free_event: order.free_event,
        },
        paymentInfo: {
          ...paymentInfo,
        },
      },
    };

    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };

    return this.http.post(
      `${environment.apiUrl}/orders`,
      JSON.stringify(postData),
      options
    );
  }

  checkoutThreeds(postData: any) {
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };

    return this.http.post(
      `${environment.apiUrl}/order/createFromThreeDS`,
      JSON.stringify(postData),
      options
    );
  }

  getOrderRequiredFee(data: {
    ticketsPrice: number[];
    total: number;
  }): Observable<OrderFee | null> {
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };

    return this.http
      .post(
        `${environment.apiUrl}/event-setting/calcOrderFee`,
        JSON.stringify({ data }),
        options
      )
      .pipe(
        map((resp: any) => {
          return {
            insuranceFee: resp.insuranceFee,
            serviceFee: resp.serviceFee,
            gateway: resp.gateway,
          };
        }),
        catchError(() => of(null))
      );
  }

  private _mapEvent(resp: any): Event[] {
    const respMapped: Event[] = [];

    resp.data.forEach((data: any) => {
      const coverData = data.attributes.cover.data;
      if (!coverData) {
        return;
      }
      const mapImagesData = data.attributes.map_images
        ? data.attributes.map_images.data
        : null;

      respMapped.push({
        id: data.id,
        videoId: data.attributes.videoId,
        applyFeed: data.attributes.applyFeed,
        category: {
          id: data.attributes.event_category.data.id,
          name: data.attributes.event_category.data.attributes.name,
          description: null,
          priority: 0,
        },
        coverUri: this._getAbsoluteUrl(coverData[0].attributes.url),
        cover: coverData,
        mapImageUri:
          mapImagesData != null
            ? this._getAbsoluteUrl(mapImagesData[0].attributes.url)
            : null,
        description: data.attributes.description,
        endAt: data.attributes.end_at,
        free: data.attributes.free,
        hasAdd: data.attributes.hasAdd,
        maxPrice: data.attributes.max_price,
        minPrice: data.attributes.min_price,
        name: data.attributes.name,
        place: data.attributes.place,
        slug: data.attributes.slug,
        startAt: data.attributes.start_at,
        vendor: data.attributes.vendor_account
          ? {
              id: data.attributes.vendor_account.data.id,
              name: data.attributes.vendor_account.data.attributes.name,
              active: data.attributes.vendor_account.data.attributes.active,
              profilePictureUri: data.attributes.vendor_account.data.attributes
                .profile_picture.data
                ? this._getAbsoluteUrl(
                    data.attributes.vendor_account.data.attributes
                      .profile_picture.data.attributes.url
                  )
                : '',
              userEmail:
                data.attributes.vendor_account.data.attributes.admin.data
                  .attributes.email,
              userFirstName:
                data.attributes.vendor_account.data.attributes.admin.data
                  .attributes.name,
              userLastName:
                data.attributes.vendor_account.data.attributes.admin.data
                  .attributes.last_name,
              totalEvents:
                data.attributes.vendor_account.data.attributes.admin.data
                  .attributes.events.data.length,
            }
          : (null as any),
      });
    });
    return respMapped;
  }

  private _getAbsoluteUrl(url: string): string {
    return this.utilService.getAbsoluteUrl(url);
  }
}
