<section>
  <header
    class="d-flex align-items-center mx-auto"
    style="min-height: 100px; max-width: 780px; margin-top: 100px"
  >
    <div class="container-fluid my-0">
      <div class="row justify-content-center">
        <div class="col-12 text-uppercase text-center mx-auto">
          <h1
            class="display-1 fw-bold"
            style="letter-spacing: -1px; margin-bottom: 42px; line-height: 75px"
          >
            Solo pagas por lo que vendes
          </h1>
          <p
            class="text-secoundary fw-bolder"
            style="max-width: 800px; margin-bottom: 42px"
          >
            Así de justos somos
          </p>
        </div>
      </div>
    </div>
  </header>
  <!-- end -->
  <div class="position-relative" style="padding-bottom: 65px">
    <div class="container-fluid h-100" style="max-width: 1020px">
      <div class="row h-100 align-items-center justify-content-between g-4">
        <div class="col-12">
          <h2
            class="display-5 d-flex align-items-center justify-content-center fw-bold text-center mb-md-2"
          >
            <span
              class="d-block border border-dark rounded-circle h3 m-0"
              style="padding: 32px 16px"
              >4.9%</span
            >
            <small
              class="rounded-pill bg-black text-white h6 d-flex align-items-center justify-content-center m-0"
              style="transform: scale(1.4); width: 24px; height: 24px"
              >+</small
            >
            <span
              class="d-block border border-dark rounded-circle d-flex align-items-center h3 m-0"
              style="padding: 32px 12px"
            >
              <small class="h6 m-0">RD</small>$25
            </span>
          </h2>
          <p
            class="text-secoundary text-center mx-auto text-uppercase"
            style="max-width: 400px; margin-bottom: 27px"
          >
            Por entrada vendida
          </p>
          <p
            class="text-secoundary text-center mx-auto text-uppercase fst-italic fw-normal"
            style="max-width: 400px"
          >
            LUEGO TUS PARTICIPANTES PAGAN UN CARGO X SERVICIO DE 8.5% + RD$15
            (MIN RD$80)
          </p>
        </div>
      </div>
    </div>
  </div>
  <!-- end -->
  <div class="position-relative" style="padding: 65px 0 120px">
    <div class="container-fluid">
      <div class="row justify-content-center align-items-center">
        <div class="col-12 col-md-auto mb-3 mb-md-5">
          <div class="mx-auto" style="max-width: 855px">
            <h2 style="line-height: 1rem; margin-bottom: 68px">
              <span
                class="d-block display-3 fw-bold text-center text-uppercase"
                style="line-height: 60px"
              >
                Todo lo que necesitas en un solo lugar
              </span>
            </h2>
            <div
              class="d-md-flex gap- border border-dark justify-content-center rounded-4"
              style="padding: 40px 60px; line-height: 40px"
            >
              <ul class="list-group bg-transparent-flush w-100">
                <li
                  class="list-group-item px-0 bg-transparent border-0 d-flex align-items-center"
                >
                  <span
                    class="material-symbols-outlined me-2"
                    style="font-size: 24px; margin-bottom: 0"
                    >local_activity</span
                  >
                  Tickets con código QR
                </li>
                <li
                  class="list-group-item px-0 bg-transparent border-0 d-flex align-items-center"
                >
                  <span
                    class="material-symbols-outlined me-2"
                    style="font-size: 24px; margin-bottom: 0"
                    >barcode</span
                  >
                  Scan app
                </li>
                <li
                  class="list-group-item px-0 bg-transparent border-0 d-flex align-items-center"
                >
                  <span
                    class="material-symbols-outlined me-2"
                    style="font-size: 24px; margin-bottom: 0"
                    >group</span
                  >
                  Evento con asientos asignados
                </li>
                <li
                  class="list-group-item px-0 bg-transparent border-0 d-flex align-items-center"
                >
                  <span
                    class="material-symbols-outlined me-2"
                    style="font-size: 24px; margin-bottom: 0"
                    >volunteer_activism</span
                  >
                  Entradas de cortesías
                </li>
                <li
                  class="list-group-item px-0 bg-transparent border-0 d-flex align-items-center"
                >
                  <span
                    class="material-symbols-outlined me-2"
                    style="font-size: 24px; margin-bottom: 0"
                    >percent</span
                  >
                  Códigos de descuentos
                </li>
                <li
                  class="list-group-item px-0 bg-transparent border-0 d-flex align-items-center"
                >
                  <span
                    class="material-symbols-outlined me-2"
                    style="font-size: 24px; margin-bottom: 0"
                    >monitoring</span
                  >
                  Analíticas
                </li>
              </ul>
              <ul class="list-group bg-transparent-flush w-100">
                <li
                  class="list-group-item px-0 bg-transparent border-0 d-flex align-items-center"
                >
                  <span
                    class="material-symbols-outlined me-2"
                    style="font-size: 24px; margin-bottom: 0"
                    >update</span
                  >
                  Pagos en 24 horas
                </li>
                <li
                  class="list-group-item px-0 bg-transparent border-0 d-flex align-items-center"
                >
                  <span
                    class="material-symbols-outlined me-2"
                    style="font-size: 24px; margin-bottom: 0"
                    >add_to_queue</span
                  >
                  Soporte técnico de primera
                </li>
                <li
                  class="list-group-item px-0 bg-transparent border-0 d-flex align-items-center"
                >
                  <span
                    class="material-symbols-outlined me-2"
                    style="font-size: 24px; margin-bottom: 0"
                    >calendar_clock</span
                  >
                  Un solo evento diferentes fechas
                </li>
                <li
                  class="list-group-item px-0 bg-transparent border-0 d-flex align-items-center"
                >
                  <span
                    class="material-symbols-outlined me-2"
                    style="font-size: 24px; margin-bottom: 0"
                    >live_tv</span
                  >
                  Eventos virtuales
                </li>
                <li
                  class="list-group-item px-0 bg-transparent border-0 d-flex align-items-center"
                >
                  <span
                    class="material-symbols-outlined me-2"
                    style="font-size: 24px; margin-bottom: 0"
                    >verified_user</span
                  >
                  Super Seguro (Somos PCI compliant)
                </li>
                <li
                  class="list-group-item px-0 bg-transparent border-0 d-flex align-items-center"
                >
                  <span
                    class="material-symbols-outlined me-2"
                    style="font-size: 24px; margin-bottom: 0"
                    >visibility</span
                  >
                  Visibilidad (+65k visitas por mes)
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-auto d-md-flex align-items-center">
          <figure class="m-0 overflow-hidden me-md-5">
            <img
              src="../../../../../assets/img/devices.png"
              class="w-100 h-100 object-fit-contain"
              style="aspect-ratio: 3 / 2; object-position: center top"
              alt=""
            />
          </figure>
          <!-- end -->
          <div>
            <div class="mb-3 text-center text-md-start">
              <a
                href="https://apps.apple.com/do/app/tix/id6450225446"
                target="_blank"
              >
                <img src="../../../../../assets/img/app-store.png" alt="" />
              </a>
            </div>
            <div class="text-center text-md-start">
              <a
                href="https://play.google.com/store/apps/details?id=com.tix.checkinapp"
              >
                <img src="../../../../../assets/img/google-play.png" alt="" />
              </a>
            </div>
          </div>
          <!-- end -->
        </div>
      </div>
    </div>
  </div>
  <!-- end -->
  <div class="position-relative py-5 bg-black text-white">
    <div class="container-fluid">
      <div class="row justify-content-center align-items-center">
        <div class="col-12 col-md-auto">
          <h2 class="mb-5 text-center display-1 fw-bold">
            Estás en buenas manos
          </h2>
          <ul
            class="list-unstyled d-inline-flex flex-wrap-545 justify-content-center gap-4 mx-auto p-0 w-100 mb-4"
          >
            <li>
              <img
                src="../../../../../assets/img/carlos-sanchez.png"
                class="object-fit-contain"
                style="max-height: 80px; object-position: center"
                alt=""
              />
            </li>
            <li>
              <img
                src="../../../../../assets/img/claro.png"
                class="object-fit-contain"
                style="max-height: 80px; object-position: center"
                alt=""
              />
            </li>
            <li>
              <img
                src="../../../../../assets/img/comedy.png"
                class="object-fit-contain"
                style="max-height: 80px; object-position: center"
                alt=""
              />
            </li>
            <li>
              <img
                src="../../../../../assets/img/cultura.png"
                class="object-fit-contain"
                style="max-height: 80px; object-position: center"
                alt=""
              />
            </li>
            <li>
              <img
                src="../../../../../assets/img/kidsfest.png"
                class="object-fit-contain"
                style="max-height: 80px; object-position: center"
                alt=""
              />
            </li>
          </ul>
          <ul
            class="list-unstyled d-inline-flex flex-wrap-545 justify-content-center gap-4 mx-auto p-0 w-100"
          >
            <li>
              <img
                src="../../../../../assets/img/escenario-360.png"
                class="object-fit-contain"
                style="max-height: 80px; object-position: center"
                alt=""
              />
            </li>
            <li>
              <img
                src="../../../../../assets/img/ted.png"
                class="object-fit-contain"
                style="max-height: 80px; object-position: center"
                alt=""
              />
            </li>
            <li>
              <img
                src="../../../../../assets/img/tovar.png"
                class="object-fit-contain"
                style="max-height: 80px; object-position: center"
                alt=""
              />
            </li>
            <li>
              <img
                src="../../../../../assets/img/unibe.png"
                class="object-fit-contain"
                style="max-height: 80px; object-position: center"
                alt=""
              />
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <!-- end -->
  <div class="position-relative" style="padding: 138px 0">
    <div class="container-fluid">
      <div class="row justify-content-center align-items-center text-center">
        <div class="col-12 mb-5">
          <h2
            class="display-4 fw-bold d-flex justify-content-center text-center mb-md-4 mx-auto"
            style="max-width: 790px"
          >
            <span>OTRAS</span>
            <span class="mx-5">VS</span>
            <span>TIX</span>
          </h2>
          <!-- <p class="text-secoundary text-center mx-auto text-uppercase" style="max-width: 600px;">Ellos no lo
                        hicieron asi que lo hicimos nosotros</p> -->
        </div>
        <div class="col-12">
          <div
            class="d-grid gap-0 mx-auto w-auto overflow-hidden mx-auto"
            style="max-width: 890px"
          >
            <ul class="list-group bg-transparent list-group-horizontal">
              <li
                class="w-50 rounded-0 py-3 align-items-start d-flex h-100 list-group-item justify-content-start border-0 bg-transparent"
              >
                <span
                  class="material-symbols-outlined text-white bg-black rounded-pill me-3"
                  style="
                    font-size: 23px;
                    padding: 2px;
                    font-variation-settings:
                      'FILL' 1,
                      'wght' 200,
                      'GRAD' 0,
                      'opsz' 24;
                  "
                  >thumb_down</span
                >
                Costo publicación RD$ 12K - 28K
              </li>
              <li
                class="w-50 rounded-0 py-3 align-items-start d-flex h-100 list-group-item justify-content-start bg-black text-white border-0 rounded-top-4"
              >
                <span
                  class="material-symbols-outlined text-black bg-white rounded-pill me-3"
                  style="
                    font-size: 23px;
                    padding: 2px;
                    font-variation-settings:
                      'FILL' 1,
                      'wght' 200,
                      'GRAD' 0,
                      'opsz' 24;
                  "
                  >thumb_up</span
                >
                Costo de publicacion Gratis
              </li>
            </ul>
            <ul class="list-group bg-transparent list-group-horizontal">
              <li
                class="w-50 rounded-0 py-3 align-items-start d-flex h-100 list-group-item justify-content-start border-0 bg-transparent"
              >
                <span
                  class="material-symbols-outlined text-white bg-black rounded-pill me-3"
                  style="
                    font-size: 23px;
                    padding: 2px;
                    font-variation-settings:
                      'FILL' 1,
                      'wght' 200,
                      'GRAD' 0,
                      'opsz' 24;
                  "
                  >thumb_down</span
                >
                Tiempo de publicación 3 - 7 días
              </li>
              <li
                class="w-50 rounded-0 py-3 align-items-start d-flex h-100 list-group-item justify-content-start bg-black text-white border-0"
              >
                <span
                  class="material-symbols-outlined text-black bg-white rounded-pill me-3"
                  style="
                    font-size: 23px;
                    padding: 2px;
                    font-variation-settings:
                      'FILL' 1,
                      'wght' 200,
                      'GRAD' 0,
                      'opsz' 24;
                  "
                  >thumb_up</span
                >
                Tiempo de publicacion 0 – 25 min
              </li>
            </ul>
            <ul class="list-group bg-transparent list-group-horizontal">
              <li
                class="w-50 rounded-0 py-3 align-items-start d-flex h-100 list-group-item justify-content-start border-0 bg-transparent"
              >
                <span
                  class="material-symbols-outlined text-white bg-black rounded-pill me-3"
                  style="
                    font-size: 23px;
                    padding: 2px;
                    font-variation-settings:
                      'FILL' 1,
                      'wght' 200,
                      'GRAD' 0,
                      'opsz' 24;
                  "
                  >thumb_down</span
                >
                No tienen Scan app
              </li>
              <li
                class="w-50 rounded-0 py-3 align-items-start d-flex h-100 list-group-item justify-content-start bg-black text-white border-0"
              >
                <span
                  class="material-symbols-outlined text-black bg-white rounded-pill me-3"
                  style="
                    font-size: 23px;
                    padding: 2px;
                    font-variation-settings:
                      'FILL' 1,
                      'wght' 200,
                      'GRAD' 0,
                      'opsz' 24;
                  "
                  >thumb_up</span
                >
                Tenemos scan app
              </li>
            </ul>
            <ul class="list-group bg-transparent list-group-horizontal">
              <li
                class="w-50 rounded-0 py-3 align-items-start d-flex h-100 list-group-item justify-content-start border-0 bg-transparent"
              >
                <span
                  class="material-symbols-outlined text-white bg-black rounded-pill me-3"
                  style="
                    font-size: 23px;
                    padding: 2px;
                    font-variation-settings:
                      'FILL' 1,
                      'wght' 200,
                      'GRAD' 0,
                      'opsz' 24;
                  "
                  >thumb_down</span
                >
                Pago 7 - 15 días
              </li>
              <li
                class="w-50 rounded-0 py-3 align-items-start d-flex h-100 list-group-item justify-content-start bg-black text-white border-0"
              >
                <span
                  class="material-symbols-outlined text-black bg-white rounded-pill me-3"
                  style="
                    font-size: 23px;
                    padding: 2px;
                    font-variation-settings:
                      'FILL' 1,
                      'wght' 200,
                      'GRAD' 0,
                      'opsz' 24;
                  "
                  >thumb_up</span
                >
                Pago 24 – 48 horas
              </li>
            </ul>
            <ul class="list-group bg-transparent list-group-horizontal">
              <li
                class="w-50 rounded-0 py-3 align-items-start d-flex h-100 list-group-item justify-content-start border-0 bg-transparent"
              >
                <span
                  class="material-symbols-outlined text-white bg-black rounded-pill me-3"
                  style="
                    font-size: 23px;
                    padding: 2px;
                    font-variation-settings:
                      'FILL' 1,
                      'wght' 200,
                      'GRAD' 0,
                      'opsz' 24;
                  "
                  >thumb_down</span
                >
                1 solo link multiples fechas
              </li>
              <li
                class="w-50 rounded-0 py-3 align-items-start d-flex h-100 list-group-item justify-content-start bg-black text-white border-0"
              >
                <span
                  class="material-symbols-outlined text-black bg-white rounded-pill me-3"
                  style="
                    font-size: 23px;
                    padding: 2px;
                    font-variation-settings:
                      'FILL' 1,
                      'wght' 200,
                      'GRAD' 0,
                      'opsz' 24;
                  "
                  >thumb_up</span
                >
                1 solo evento multiples fechas
              </li>
            </ul>
            <ul class="list-group bg-transparent list-group-horizontal">
              <li
                class="w-50 rounded-0 py-3 align-items-start d-flex h-100 list-group-item justify-content-start border-0 bg-transparent"
              >
                <span
                  class="material-symbols-outlined text-white bg-black rounded-pill me-3"
                  style="
                    font-size: 23px;
                    padding: 2px;
                    font-variation-settings:
                      'FILL' 1,
                      'wght' 200,
                      'GRAD' 0,
                      'opsz' 24;
                  "
                  >thumb_down</span
                >
                No puedes programar Early Birds
              </li>
              <li
                class="w-50 rounded-0 py-3 align-items-start d-flex h-100 list-group-item justify-content-start bg-black text-white border-0"
              >
                <span
                  class="material-symbols-outlined text-black bg-white rounded-pill me-3"
                  style="
                    font-size: 23px;
                    padding: 2px;
                    font-variation-settings:
                      'FILL' 1,
                      'wght' 200,
                      'GRAD' 0,
                      'opsz' 24;
                  "
                  >thumb_up</span
                >
                Puedes programar Early Birds
              </li>
            </ul>
            <ul class="list-group bg-transparent list-group-horizontal">
              <li
                class="w-50 rounded-0 py-3 align-items-start d-flex h-100 list-group-item justify-content-start border-0 bg-transparent"
              >
                <span
                  class="material-symbols-outlined text-white bg-black rounded-pill me-3"
                  style="
                    font-size: 23px;
                    padding: 2px;
                    font-variation-settings:
                      'FILL' 1,
                      'wght' 200,
                      'GRAD' 0,
                      'opsz' 24;
                  "
                  >thumb_down</span
                >
                No Códigos de descuentos
              </li>
              <li
                class="w-50 rounded-0 py-3 align-items-start d-flex h-100 list-group-item justify-content-start bg-black text-white border-0"
              >
                <span
                  class="material-symbols-outlined text-black bg-white rounded-pill me-3"
                  style="
                    font-size: 23px;
                    padding: 2px;
                    font-variation-settings:
                      'FILL' 1,
                      'wght' 200,
                      'GRAD' 0,
                      'opsz' 24;
                  "
                  >thumb_up</span
                >
                Si puedes crear codigos de descuentos
              </li>
            </ul>
            <ul class="list-group bg-transparent list-group-horizontal">
              <li
                class="w-50 rounded-0 py-3 align-items-start d-flex h-100 list-group-item justify-content-start border-0 bg-transparent"
              >
                <span
                  class="material-symbols-outlined text-white bg-black rounded-pill me-3"
                  style="
                    font-size: 23px;
                    padding: 2px;
                    font-variation-settings:
                      'FILL' 1,
                      'wght' 200,
                      'GRAD' 0,
                      'opsz' 24;
                  "
                  >thumb_down</span
                >
                Promoción de tu evento
              </li>
              <li
                class="w-50 rounded-0 py-3 align-items-start d-flex h-100 list-group-item justify-content-start bg-black text-white border-0"
              >
                <span
                  class="material-symbols-outlined text-black bg-white rounded-pill me-3"
                  style="
                    font-size: 23px;
                    padding: 2px;
                    font-variation-settings:
                      'FILL' 1,
                      'wght' 200,
                      'GRAD' 0,
                      'opsz' 24;
                  "
                  >thumb_up</span
                >
                Apoyo total en la promoción de tu evento
              </li>
            </ul>
            <ul class="list-group bg-transparent list-group-horizontal">
              <li
                class="w-50 rounded-0 py-3 align-items-start d-flex h-100 list-group-item justify-content-start border-0 bg-transparent text-start"
              >
                <span
                  class="material-symbols-outlined text-white bg-black rounded-pill me-3"
                  style="
                    font-size: 23px;
                    padding: 2px;
                    font-variation-settings:
                      'FILL' 1,
                      'wght' 200,
                      'GRAD' 0,
                      'opsz' 24;
                  "
                  >thumb_down</span
                >
                Tienes que contactar para hacer cualquier cambio a tu evento
              </li>
              <li
                class="w-50 rounded-0 py-3 align-items-start d-flex h-100 list-group-item justify-content-start bg-black text-white border-0 rounded-bottom-4"
              >
                <span
                  class="material-symbols-outlined text-black bg-white rounded-pill me-3"
                  style="
                    font-size: 23px;
                    padding: 2px;
                    font-variation-settings:
                      'FILL' 1,
                      'wght' 200,
                      'GRAD' 0,
                      'opsz' 24;
                  "
                  >thumb_up</span
                >
                Apoyo total en la promoción de tu evento
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- end -->
</section>
