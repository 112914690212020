import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { TicketStatus } from '../../../../../core/models/ticket.status';
import TicketType from '../../../../../core/models/ticket.type';
import Ticket from '../../../../../core/models/ticket';
import { UtilService } from '../../../../../core/services/util.service';
import { Fancybox } from '@fancyapps/ui';
import { Meta } from '@angular/platform-browser';
import { RowBitmap, Seat } from 'src/app/core/functions/rowBitmap';
import { Product, ProductSeat } from 'src/app/core/types/product.type';

@Component({
  selector: 'seat-picker-button',
  templateUrl: './seat-picker-button.component.html',
  styleUrls: ['./seat-picker-button.component.css'],
})
export class SeatPickerButtonComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input() eventMapImgSrc: string = '';
  @Input() ticketType: TicketType | null = null;
  @Input() product!: Product;

  @Output() ticketEventEmitter = new EventEmitter<TicketType>();

  @ViewChild('mapContainer') mapContainer!: ElementRef<HTMLElement>;
  @ViewChild('fancyContainer') fancyContainer!: ElementRef<HTMLElement>;

  @Output()
  productEventEmitter = new EventEmitter<Product>();
  selectedSeatsCount = 0;

  selectedSeats: ProductSeat[] = [];

  constructor(
    private utilService: UtilService,
    private meta: Meta
  ) {}
  ngOnInit(): void {
    if (!this.ticketType) return;
    const key = this.getRowsNotificationBitmapEventListenerKey(
      this.ticketType.id
    );
    this.utilService
      .getSocket()
      .on(key, (data: { row_tracker_id: number; rbitmap: number }) => {
        const rt = this.ticketType?.rows_trackers.find(
          (rt) => rt.id === data.row_tracker_id
        );

        rt!.rbitmap = data.rbitmap;
      });
  }

  getTicketRows() {
    const rows = this.ticketType?.rows_trackers.map((rt) => rt.prefix);
    return rows || [];
  }

  ngAfterViewInit() {
    if (this.mapContainer) {
      Fancybox.bind(this.mapContainer.nativeElement, '[data-fancybox]', {
        parentEl: this.fancyContainer.nativeElement,
        Images: {
          initialSize: 'max',
        },
      });
    }
  }

  ngOnDestroy() {
    if (this.mapContainer) {
      Fancybox.unbind(this.mapContainer.nativeElement);
      Fancybox.close();
    }

    if (!this.ticketType) return;

    const key = this.getRowsNotificationBitmapEventListenerKey(
      this.ticketType.id
    );
    const socket = this.utilService.getSocket();
    socket.removeAllListeners(key);
  }

  get minimumRequiredMissing() {
    if (!this.product) return true;
    return (
      this.product.qty < this.ticketType!.min_tickets_per_order &&
      this.product.qty > 0
    );
  }

  public bookSeats(): void {
    if (this.minimumRequiredMissing) {
      alert(
        'Mínimo de tickets por orden: ' + this.ticketType!.min_tickets_per_order
      );
      return;
    }

    this.meta.updateTag({
      name: 'viewport',
      content: 'width=device-width, initial-scale=1.0',
    });

    if (this.ticketType) this.ticketEventEmitter.emit(this.ticketType);
  }

  getAvailableLabel(): string {
    if (this.ticketType) {
      const availableTotal = this.ticketType.lefts;

      const label = this.ticketType.isSeat
        ? availableTotal > 1
          ? 'asientos'
          : 'asiento'
        : availableTotal > 1
          ? 'tickets'
          : 'ticket';

      const availableLabel = availableTotal > 1 ? 'disponibles' : 'disponible';

      return `${availableTotal} ${label} ${availableLabel}`;
    }

    return '';
  }

  getTotalSelectedLabel(): string {
    if (this.ticketType) {
      const total = this.getTotalSeatsSelected();

      return total === 1 ? `${total} elegido` : `${total} elegidos`;
    }

    return '';
  }

  getTotalSeatsSelected(): number {
    return this.product.qty;
  }

  isSelectedSeat(seatIndex: number, prefix: string) {
    const rowTracker = this.getRowTrackerByPrefix(prefix);
    if (!rowTracker) return;
    return !!this.selectedSeats.find(
      (s) => s.seat_index === seatIndex && s.tracker_id === rowTracker.id
    );
  }

  onSeatToggle(prefix: string, seatIndex: number, event: Event) {
    if (!this.product) return;
    const target = event.target as HTMLInputElement;
    const rowTracker = this.getRowTrackerByPrefix(prefix);
    if (!rowTracker || !this.ticketType) return;
    const productSeat = {
      tracker_id: rowTracker.id,
      seat_index: seatIndex,
    };

    if (!this.product.seats) this.product.seats = [];
    const isSelectedSeat = (s: ProductSeat) =>
      s.tracker_id && rowTracker.id && s.seat_index === seatIndex;
    const seatProductIndex = this.product.seats.findIndex(isSelectedSeat);

    if (!target.checked) {
      this.product.seats.splice(seatProductIndex, 1);
      this.removeSelectedSeat(productSeat);
      this.product.qty--;
      this.productEventEmitter.emit(this.product);
      return;
    }

    if (this.ticketType.max_tickets_per_order < this.product.qty + 1) {
      alert(
        'Tickets máximos por orden: ' + this.ticketType!.max_tickets_per_order
      );
      target.checked = false;
      return;
    }

    this.product.seats.push(productSeat);
    this.selectedSeats.push(productSeat);
    this.product.qty++;
    this.productEventEmitter.emit(this.product);
  }

  isTicketUsed(ticket: Ticket): boolean {
    return ticket.status === TicketStatus.USED;
  }

  seatsByRowPrefix(prefix: string) {
    const rowTracker = this.getRowTrackerByPrefix(prefix);
    if (!rowTracker) return [];
    const seats = RowBitmap.getSeats(rowTracker);

    return this.sortSeatsByPrefix(seats.filter(seat => !seat.isOccupied));
  }

  getMapEventImageUrl(mapImage: string): string {
    return this.utilService.getAbsoluteUrl(mapImage);
  }

  sortSeatsByPrefix(seats: Seat[]) {
    const result = seats.sort((seatA, seatB) => {
      const aNumber = parseInt(seatA.prefix);
      const bNumber = parseInt(seatB.prefix);

      return aNumber - bNumber;
    });

    return result;
  }

  sortTicketsByLabel = (tickets: any[], row: string) => {
    const result = tickets.sort((ticketA: any, ticketB: any) => {
      const aNumber = parseInt(ticketA.label.replace(row, '').trim());
      const bNumber = parseInt(ticketB.label.replace(row, '').trim());

      if (aNumber > bNumber) return 1;

      return -1;
    });

    return result;
  };
  protected readonly TicketStatus = TicketStatus;

  openModal() {
    this.meta.updateTag({
      name: 'viewport',
      content:
        'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no, shrink-to-fit=no',
    });
  }

  private getRowsNotificationBitmapEventListenerKey(ticketTypeId: number) {
    return `rows_${ticketTypeId}_notification_rbitmap`;
  }

  private removeSelectedSeat(seat: ProductSeat) {
    const index = this.selectedSeats.findIndex(
      (s) =>
        s.seat_index === seat.seat_index && s.tracker_id === seat.tracker_id
    );

    if (index < 0) return;
    this.selectedSeats.splice(index, 1);
  }

  private getRowTrackerByPrefix(prefix: string) {
    return this.ticketType?.rows_trackers.find((rt) => rt.prefix === prefix);
  }
}
