import { Directive, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { FormControlName } from '@angular/forms';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[appFormInput]',
})
export class FormInputDirective implements OnDestroy, OnInit {
  changeSubscription: Subscription | undefined;

  constructor(
    private el: ElementRef,
    private formControlName: FormControlName
  ) {}

  ngOnInit() {
    this.changeSubscription =
      this.formControlName.control?.valueChanges?.subscribe((value) => {
        const classList = this.el.nativeElement.classList;

        if (
          this.formControlName.control.invalid &&
          !classList.contains('is-invalid')
        )
          classList.add('is-invalid');

        if (
          this.formControlName.control.valid &&
          classList.contains('is-invalid')
        )
          classList.remove('is-invalid');
      });
  }

  ngOnDestroy() {
    this.changeSubscription?.unsubscribe();
  }
}
