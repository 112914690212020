import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import VendorMessageDto from '../../../core/dtos/vendor-message.dto';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ContactService {
  constructor(private http: HttpClient) {}

  sendMessage(data: VendorMessageDto) {
    return this.http.post(
      `${environment.apiUrl}/static-page/contact/send-message/`,
      data
    );
  }
}
