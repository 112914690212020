import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AnalitycsService {
  constructor(private http: HttpClient) {}

  logEventVisit(postData: any): Observable<any> {
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };

    return this.http.post(
      `${environment.apiUrl}/analitycs`,
      JSON.stringify(postData),
      options
    );
  }
}
