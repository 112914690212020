import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { catchError, map, Observable, of } from 'rxjs';
import Category from '../../../core/models/category';

@Injectable({
  providedIn: 'root',
})
export class CategoryService {
  constructor(private http: HttpClient) {}

  getCategories(): Observable<Category[]> {
    return this.http
      .get(
        `${environment.apiUrl}/event-categories?fields[0]=name&fields[1]=description&fields[2]=priority&sort[0]=priority%3Aasc`
      )
      .pipe(
        map((resp: any) => {
          const categories: Category[] = [];
          resp.data.forEach((_category: any) => {
            categories.push({
              description: _category.attributes.description,
              id: _category.id,
              name: _category.attributes.name,
              priority: _category.attributes.priority,
            });
          });
          return categories;
        }),
        catchError(() => of([]))
      );
  }
}
