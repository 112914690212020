import {
  Component,
  OnInit,
  ViewChild,
} from '@angular/core';
import * as moment from 'moment';
import { EventService } from '../../services/event.service';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageService } from '../../../../core/services/storage.service';
import { Constants } from '../../../../core/utils/constants';
import Banner from '../../../../core/models/banner';
import TicketType from '../../../../core/models/ticket.type';
import { TicketStatus } from '../../../../core/models/ticket.status';
import Order from '../../../../core/models/order';
import Ticket from '../../../../core/models/ticket';
import { UtilService } from '../../../../core/services/util.service';
import { BaseEventComponent } from '../../components/base-event/base-event.component';
import { ResponseTicketTypeOrGroupedByDate } from '../../services/ticket.service';
import TicketVerificationDto from '../../../../core/dtos/ticket-verification-dto';
import SendTicketVerificationDto from '../../../../core/dtos/send-ticket-verification-dto';
import { lastValueFrom, Subscription } from 'rxjs';
import { AnalitycsService } from '../../../../core/services/analitycs.service';
import { DetailService } from './services/detail.service';
import { LoadingComponent } from '../../../../core/components/loading/loading.component';
import { Fancybox } from '@fancyapps/ui';
import { Product } from 'src/app/core/types/product.type';
import {
  CheckoutSessionErrorResponse,
  CheckoutSessionService,
  CheckoutSessionStartResponse,
} from '../../services/checkout-session.service';
import { CheckoutSessionCode } from 'src/app/core/enums/checkout-session-code.enum';

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.css'],
  providers: [DetailService],
})
export class DetailComponent extends BaseEventComponent implements OnInit {
  @ViewChild(LoadingComponent) loadingComponent!: LoadingComponent;

  public totalAmount: number = 0;
  public selectedTicketsQuantity = 0;
  public selectedTicketTypeList: TicketType[] = [];
  public proceeding: boolean = false;
  public copyMessage = 'Copiar';
  public copyIcon = 'content_copy';
  public soonerTTDate = '';
  public soonerTTs: TicketType[] = [];
  public isOpenTicketsSelection = true;

  private refreshSubscription?: Subscription;

  constructor(
    private router: Router,
    private utilService: UtilService,
    eventService: EventService,
    route: ActivatedRoute,
    localStorageService: StorageService,
    private analyticsService: AnalitycsService,
    private detailService: DetailService,
    private checkoutSessionService: CheckoutSessionService
  ) {
    super(eventService, localStorageService, route);
  }

  ngOnInit(): void {
    this.setProducts();
  }

  get eventUrl() {
    return window.location.href;
  }

  get soonerDate() {
    const selectedTicketTypes = this.getSelectedTicketTypes();
    if (!this.event?.startAt) {
      return '';
    }

    if (!selectedTicketTypes?.length) {
      return this.event?.startAt;
    }

    let sooner = new Date(this.event.startAt);
    for (const tt of selectedTicketTypes) {
      if (moment(tt.start_on).isSameOrBefore(sooner)) {
        sooner = new Date(tt.start_on);
      }
    }
    return sooner?.toISOString();
  }

  override onInit(): void {
    this.proceeding = false;
    this.totalAmount = 0;
    this.selectedTicketTypeList = [];

    this.banners = this.getBanners();

    this.refreshSubscription =
      this.detailService.onRefreshTicketTypes.subscribe(
        this.refreshTicketTypes.bind(this)
      );

    this.eventService.ticketService
      .getTicketType(this.event?.id ?? 0)
      .subscribe((resp: ResponseTicketTypeOrGroupedByDate) => {
        this.ticketTypeList = resp;
      });

    if (this.event?.id) {
      const startAt = this.event?.startAt.toString();
      if (startAt) {
        this.soonerTTDate = startAt;
      }

      lastValueFrom(
        this.analyticsService.logEventVisit({
          data: {
            type: 'event',
            item_id: this.event.id,
          },
        })
      );
    }
  }

  override ngOnDestroy() {
    super.ngOnDestroy();

    this.refreshSubscription?.unsubscribe();
  }

  private setProducts() {
    this.products = [];
    Object.values(this.ticketTypeList).forEach((tt: TicketType) => {
      this.products.push({
        ticket_type_id: tt.id,
        qty: 0,
        seats: [],
      });
    });
  }

  private flatIfIsGroupedByDate() {
    const isGroupedByDate = !Array.isArray(this.ticketTypeList);
    let list: TicketType[];

    if (isGroupedByDate) {
      list = Object.values(this.ticketTypeList).flat();
    } else {
      list = this.ticketTypeList as TicketType[];
    }

    return list;
  }

  private async refreshTicketTypes() {
    if (this.proceeding) return;

    this.loadingComponent.toggle('Refrescando disponibilidad de tickets');

    await new Promise((resolve, reject) => {
      setTimeout(resolve, 1000);
    });

    this.eventService.ticketService
      .getTicketType(this.event?.id ?? 0)
      .subscribe((resp: ResponseTicketTypeOrGroupedByDate) => {
        this.loadingComponent.toggle();

        this.ticketTypeList = resp;

        this.selectedTicketTypeList.forEach((tt) => {
          const ticketType = this.flatIfIsGroupedByDate().find(
            (t) => t.id == tt.id
          );

          const used = JSON.parse(JSON.stringify(tt.tickets)).filter(
            (t: any) => t.status == 'used'
          );

          const available = JSON.parse(
            JSON.stringify(ticketType!.tickets)
          ).filter((t: any) => t.status == 'available');

          const usedLength =
            available.length >= used.length ? used.length : available.length;

          for (let i = 0; i < usedLength; i++) {
            let usedTicket = used[i];

            let index = 0;

            if (ticketType!.isSeat) {
              index = ticketType!.tickets.findIndex(
                (t) =>
                  t.id == usedTicket.id && t.status == TicketStatus.AVAILABLE
              );
            } else {
              index = ticketType!.tickets.findIndex(
                (t) => t.status == TicketStatus.AVAILABLE
              );
            }

            if (index != -1) ticketType!.tickets[index] = usedTicket;
          }
        });
      });
  }

  onProductChangeListener(product: Product) {
    const index = this.products.findIndex(
      (p) => p.ticket_type_id === product.ticket_type_id
    );

    if (index === -1) {
      this.products.push(product);
    } else if (product.qty <= 0) {
      this.products.splice(index, 1);
    } else {
      this.products[index] = product;
    }

    this.calcTotalAmount();
  }

  get disabledToCheckout() {
    return !this.products.some((p) => p.qty > 0);
  }

  async proceedToCheckout(): Promise<void> {
    this.products = this.products.filter((p) => p.qty > 0);
    if (this.products.length === 0 || !this.event?.id) return;
    try {
      this.isOpenTicketsSelection = false;
      const session = await this.checkoutSessionService.startSession(
        this.event.id,
        this.products
      );
      const saved = this.saveState(session);
      if (!saved) {
        this.isOpenTicketsSelection = true;
        return;
      }
      this.router.navigate([`/order/checkout/${this.event?.slug}`]);
    } catch (error: any) {
      this.handleCheckoutSessionErrors(error);
      return;
    }
  }

  private handleCheckoutSessionErrors(error: any) {
    const checkoutError = error?.error?.error
      ?.details as CheckoutSessionErrorResponse;
    switch (checkoutError.code) {
      case CheckoutSessionCode.CHECKOUT_INVALID_TICKET_TYPE:
        alert('Algunos de los tipos de tickets seleccionados ya no existen.');
        break;
      case CheckoutSessionCode.CHECKOUT_INVALID_TICKET_TYPE_QTY:
        alert(
          'La cantidad de tickets solicitada no es válida o supera el límite disponible.'
        );
        break;
      case CheckoutSessionCode.CHECKOUT_INVALID_TICKET_TYPE_SEAT:
        alert('Uno o más asientos seleccionados ya no están disponibles.');
        break;
      default:
        alert('Ha ocurrido un error inesperado. Intenta nuevamente.');
        break;
    }
  }

  private getSelectedTicketTypes() {
    let selectedTicketTypes: TicketType[];
    const ticketTypesIds = this.products.map((p) => p.ticket_type_id);
    const findCB = (tt: TicketType) => ticketTypesIds.includes(tt.id);
    if (Array.isArray(this.ticketTypeList)) {
      selectedTicketTypes = this.ticketTypeList.filter(findCB);
    } else {
      const list = Object.values(this.ticketTypeList).flat();
      selectedTicketTypes = list.filter(findCB);
    }

    return selectedTicketTypes;
  }

  private saveState(session: CheckoutSessionStartResponse) {
    const selectedTicketTypes = this.getSelectedTicketTypes();
    const promoterSlug = this.route.snapshot.queryParamMap.get('promoter');
    const sessionData = {
      sessionToken: session.data.session_token,
      products: this.products,
      selectedTicketTypes,
      event: this.event,
      promoterSlug: promoterSlug || '',
    };

    sessionStorage.setItem(
      Constants.Keys.CheckoutSession,
      JSON.stringify(sessionData)
    );
    return true;
  }

  private calcTotalAmount() {
    this.totalAmount = 0;
    this.products.forEach(({ qty, ticket_type_id }) => {
      const findCB = (x: TicketType) => {
        return x.id === ticket_type_id;
      };
      let tt: TicketType | undefined;
      if (Array.isArray(this.ticketTypeList)) {
        tt = this.ticketTypeList.find(findCB);
      } else {
        const list = Object.values(this.ticketTypeList).flat();
        tt = list.find(findCB);
      }
      this.totalAmount += this.calcAmount(qty, tt);
    });
  }

  private calcAmount(qty: number, tt?: TicketType) {
    if (!tt) return 0;
    return tt.price * qty;
  }

  private prepareTicketSToVerify(): SendTicketVerificationDto[] {
    const ticketsIdsValidation: SendTicketVerificationDto[] = [];

    for (let i = 0; i < this.selectedTicketTypeList.length; i++) {
      const _ticketType: TicketType = JSON.parse(
        JSON.stringify(this.selectedTicketTypeList[i])
      );

      ticketsIdsValidation.push({
        id: _ticketType.id,
        isSeat: _ticketType.isSeat,
        isFree: _ticketType.isFree,
        tickets: [],
      });

      for (let x = 0; x < _ticketType.tickets.length; x++) {
        const _ticket: Ticket = _ticketType.tickets[x];

        if (_ticket.status === TicketStatus.USED)
          ticketsIdsValidation[i].tickets.push(_ticket.id);
      }
    }

    return ticketsIdsValidation;
  }

  private setAvailableTickets(tickets: TicketVerificationDto[], order: Order) {
    const selectedTMP = JSON.parse(
      JSON.stringify(this.selectedTicketTypeList)
    ) as typeof this.selectedTicketTypeList;

    selectedTMP.forEach((t) => {
      const availableTt = tickets.find((tt) => tt.id == t.id)!;

      t.tickets = availableTt.tickets;

      order.tickets.push(t);
    });

    tickets.forEach((ticketType) => {
      order.ticketIds = order.ticketIds.concat(
        ticketType.tickets.map((t) => t.id)
      );
    });

    this.storageService.set(Constants.Keys.EventOrder, JSON.stringify(order));
  }

  getMapEventImageUrl(mapImage: string): string {
    return this.utilService.getAbsoluteUrl(mapImage);
  }

  private getBanners(): Banner[] {
    const banners = this.event?.cover.map((cover) => {
      return {
        imageUri: cover.attributes.url ?? '',
        active: true,
        description: null,
        endAt: null,
        id: 0,
        link: null,
        priority: 0,
        startAt: null,
        title: '',
      };
    });

    return banners as Banner[];
  }

  openMap() {
    new Fancybox([
      {
        src: this.getMapEventImageUrl(this.event?.mapImageUri ?? ''),
      },
    ]);
  }
}
