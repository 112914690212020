import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';

import { CategoryService } from '../../services/category.service';
import Category from '../../../../core/models/category';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'event-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.css'],
})
export class FilterComponent implements OnInit, OnDestroy {
  @Output() categoryDispatcher = new EventEmitter<Category>();
  @Output() queryDispatcher = new EventEmitter<string>();

  categories: Category[] = [];
  indexActive: number = 0;

  private _categorySubscription: Subscription | null = null;

  public activeRouteSubscriber = this.activeRoute.params.subscribe(
    (hashParams) => {
      const hash = hashParams['category_hash'];
      this._findCategoryByHash(hash);
    }
  );

  constructor(
    private categoryService: CategoryService,
    private router: Router,
    private activeRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    // Default category
    this.categories.push({
      id: 0,
      name: 'Todos',
      description: null,
      priority: 0,
    });

    this._categorySubscription = this.categoryService
      .getCategories()
      .subscribe((rs) => {
        this.categories.push(...rs);
      });
  }

  ngOnDestroy(): void {
    this.activeRouteSubscriber.unsubscribe();
    this._categorySubscription?.unsubscribe();
  }

  onClickCategory(index: number) {
    this.indexActive = index;
    const category = this.categories[index];
    this.router.navigate([`/events/${category.name.toLowerCase()}`]);
    this.categoryDispatcher.emit(category);
  }

  onSearchEvent(criteria: any) {
    this.onClickCategory(0);

    this.queryDispatcher.emit(criteria.target.value);
  }

  private _findCategoryByHash(hash: string) {
    const index =
      this.categories.findIndex(
        (_cag: Category) => _cag.name.toLowerCase() === hash
      ) ?? -1;

    if (index >= 0) {
      this.indexActive = index;
      this.categoryDispatcher.emit(this.categories[index]);
    }
  }
}
