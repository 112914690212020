import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { StorageService } from '../../../../core/services/storage.service';
import { Location } from '@angular/common';
import Order from '../../../../core/models/order';
import { Constants } from '../../../../core/utils/constants';
import TicketType from '../../../../core/models/ticket.type';
import { UtilService } from '../../../../core/services/util.service';
import { EventService } from '../../services/event.service';
import { CouponService } from '../../services/coupon.service';
import PaymentInfo from 'src/app/core/models/payment.info';
import { LoadingComponent } from '../../../../core/components/loading/loading.component';
import { environment } from '../../../../../environments/environment';
import { FormControl, Validators } from '@angular/forms';
import { lastValueFrom, Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { ThreeDSChallengeComponent } from './three-dschallenge/three-dschallenge.component';
import { OrderService } from '../../../order/services/order.service';
import Coupon from '../../../../core/models/coupon';
import CouponType from '../../../../core/enums/couponType.enum';
import { getOrderCreationErrorMessage } from 'src/app/core/utils/getOrderCreationErrorMessage';
import { OrderCreationError } from 'src/app/core/enums/orderCreationError.enum';
import {
  ErrorInformation,
  ThreeDSCybersourceService,
} from 'src/app/services/three-dscybersource.service';
import { OrderPrices } from 'src/app/core/types/OrderPrices.type';
import { TixGateway } from 'src/app/core/enums/tixGateway.enum';
import {
  CheckoutAzulResponse,
  CheckoutResponse,
  CheckoutSessionService,
  CheckoutTixErrorResponse,
  NonEmptyDiscountTokens,
} from '../../services/checkout-session.service';
import { Product } from 'src/app/core/types/product.type';
import EventModel from 'src/app/core/models/event';

export type ExplicitCouponType<T = CouponType> = Omit<Coupon, 'type'> & {
  type: T;
};
export type ExplicitCouponsType<T = CouponType> = ExplicitCouponType<T>[];

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.css'],
})
export class CheckoutComponent implements OnInit, OnDestroy {
  @ViewChild(LoadingComponent) loadingComponent!: LoadingComponent;
  @ViewChild('threeChallengeModal')
  threeChallengeModal!: ThreeDSChallengeComponent;
  orderPrice: OrderPrices = {
    fees: {
      insuranceFee: 0,
      serviceFee: 0,
    },
    discounted: 0,
    orderAmountWithDiscount: 0,
    checkoutSessionTimestamp: 0,
    gateway: TixGateway.AZUL,
    orderAmount: 0,
    securedTotal: 0,
    discount: 0,
    total: 0,
  };

  selectedTicketTypes: TicketType[] = [];
  selectedSeats: string[] = [];
  promoterSlug = '';
  products: Product[] = [];
  event!: EventModel;

  showSummary = false;
  emailInput = new FormControl('', [Validators.required, Validators.email]);
  emailInputSubscription: Subscription | undefined;
  dates: string[] = [];
  gateway: string = '';

  couponError = '';

  public order: Order | null = null;
  public couponCode: string = '';
  public couponButtonLabel: string = 'Aplicar';
  public error:
    | {
        code: string;
        error: string;
        msg: string;
        iso_code: string;
        tix_error: OrderCreationError;
      }
    | string
    | { recommendation: string; response: string; code: string }
    | { code: string; reason: string; message: string }
    | undefined = undefined;
  public insuranceFee: number = 0;

  public checkoutTimestamp: number = 0;

  public timeoutMinutes: number = -1;
  public timeoutSeconds: number = -1;

  public codeCoupons: ExplicitCouponsType<CouponType.Code> = [];
  public cardCoupon?: ExplicitCouponType<CouponType.CreditCard>;
  public discountTokens: string[] = [];

  private countDownInterval: NodeJS.Timer | null = null;

  public buttonStatus = {
    coupon: true,
    checkout: true,
  };

  public buyerData = {
    name: '',
    email: '',
    coupon: null,
  };

  public paymentInfo: PaymentInfo = {
    card: '',
    cvc: '',
    expDate: '',
    name: '',
    secured: false,
  };

  private sessionToken: string = '';

  get eventFree() {
    return this.order?.subTotal === 0;
  }

  getValidTicketTypes(coupon: Coupon) {
    if (!coupon.ticket_types?.length) return '';

    const names = coupon.ticket_types.map((tt) => tt.name).join(', ');

    return `PARA TICKETS: ${names}`;
  }

  get isEmailInvalid() {
    const result = this.emailInput.errors
      ? this.emailInput.errors['email']
      : false;

    return result;
  }

  get ticketsReleasedEvents() {
    return 'on_hold_tickets_released';
  }

  get startTimerEvent() {
    return 'start_checkout_timer';
  }

  get codeCouponsDiscount() {
    return this.orderService.codeCouponsDiscount(this.order);
  }

  get couponsNames() {
    return this.codeCoupons.map((c) => c.code).join(', ');
  }

  threeDSForm: SafeHtml = '';

  constructor(
    private route: ActivatedRoute,
    private storageService: StorageService,
    private checkoutSessionService: CheckoutSessionService,
    private router: Router,
    private location: Location,
    private utilService: UtilService,
    private eventService: EventService,
    private couponService: CouponService,
    private orderService: OrderService,
    private sanitizer: DomSanitizer,
    private threedsCybersourceService: ThreeDSCybersourceService
  ) {}

  async ngOnInit(): Promise<void> {
    this.emailInputSubscription = this.emailInput.valueChanges.subscribe(
      (value) => {
        this.buyerData.email = value!;
      }
    );

    try {
      const retrieved = this.loadState();
      if (!retrieved) {
        this.goBackToEvent();
        return;
      }

      this.addStartOnDates(this.selectedTicketTypes);

      await this.applyPrices();

      const _timestamp = this.storageService.get(
        Constants.Keys.CheckoutTimeout,
        Constants.StorageType.Session
      );

      if (_timestamp !== null) {
        this.checkoutTimestamp = parseInt(_timestamp);
      } else {
        this.checkoutTimestamp = 0;
      }

      this._setTicketSocketListener();
      this.paymentInfo.secured = true;
    } catch (err: any) {
      console.log(err);
      this.goBackToEvent();
    }
  }

  ngOnDestroy() {
    this.emailInputSubscription?.unsubscribe();
    this._clearSession();
  }

  getDiscountedByCardDiscount() {
    if (!this.cardCoupon) return 0;

    const cardCouponDiscount = this.cardCoupon.discount || 0;
    const orderAmount = this.orderPrice.orderAmount;

    return orderAmount * (cardCouponDiscount / 100);
  }

  private loadState(): boolean {
    const sessionData = sessionStorage.getItem(Constants.Keys.CheckoutSession);
    if (!sessionData) {
      this.goBackToEvent();
      return false;
    }
    const { sessionToken, products, selectedTicketTypes, event, promoterSlug } =
      JSON.parse(sessionData);
    this.sessionToken = sessionToken;
    this.products = products;

    this.selectedTicketTypes = selectedTicketTypes;
    this.setSelectedSeats();

    this.event = event;
    this.promoterSlug = promoterSlug;
    return true;
  }

  private setSelectedSeats() {
    this.products.forEach((p) => {
      if (!p.seats?.length) return;
      const tt = this.selectedTicketTypes.find(
        (t) => t.id === p.ticket_type_id
      );

      p.seats.forEach((s) => {
        const rowTracker = tt?.rows_trackers.find(
          (rt) => rt.id === s.tracker_id
        );
        if (!rowTracker) return;

        this.selectedSeats.push(`${rowTracker.prefix}${s.seat_index}`);
      });
    });
  }

  private _clearSession(pending = true): void {
    if (this.countDownInterval != null) {
      if (pending)
        this.utilService
          .getSocket()
          .emit('release_hold_tickets', this.order?.ticketIds ?? []);
      this.utilService.getSocket().removeListener(this.ticketsReleasedEvents);
      this.utilService.getSocket().removeListener(this.startTimerEvent);

      clearInterval(this.countDownInterval as any);
      this.countDownInterval = null;
    }

    if (this.sessionToken) {
      this.checkoutSessionService.endSession(this.sessionToken);
    }

    this.order = null;
    sessionStorage.removeItem(Constants.Keys.CheckoutSession);
    this.storageService.remove(Constants.Keys.EventOrder);
    this.storageService.remove(
      Constants.Keys.CheckoutTimeout,
      Constants.StorageType.Session
    );
    this.storageService.remove(
      Constants.Keys.CheckoutTimeoutRemainingSeconds,
      Constants.StorageType.Session
    );
    this.storageService.remove(
      Constants.Keys.CheckoutTimeoutTicketHeld,
      Constants.StorageType.Session
    );
  }

  back(): void {
    this._clearSession();
    this.location.back();
  }

  applyInsuranceFee(): void {
    this.paymentInfo.secured = true;
  }

  removeInsuranceFee(): void {
    this.paymentInfo.secured = false;
  }

  calcTotal(): void {
    if (this.order) {
      const { subTotal, insuranceFee, serviceFee } = this.order;

      let cardDiscount = this.cardCoupon
        ? this.order.subTotal * (this.cardCoupon.discount / 100)
        : 0;

      if (this.cardCoupon) {
        const result = this.order.coupons?.find(
          (c) => c.id == this.cardCoupon?.id
        );
        result!.discounted = cardDiscount;
      }

      const discountedSubTotal =
        subTotal - this.codeCouponsDiscount - cardDiscount;

      this.order.discounted = this.codeCouponsDiscount - cardDiscount;
      this.order.totalToPay = discountedSubTotal + insuranceFee + serviceFee;
    }
  }

  getEventImageUrl(): string {
    return this.utilService.getAbsoluteUrl(this.event.coverUri ?? '');
  }

  getTotalTickets(): number {
    return this.products.reduce((prev, ac) => (prev += ac.qty), 0);
  }

  applyCoupon(): void {
    if (!this.couponCode) return;

    this.buttonStatus.coupon = false;
    this.couponButtonLabel = '...';
    this.couponService
      .find(
        this.couponCode,
        this.event.id,
        this.selectedTicketTypes.map((t) => t.id)
      )
      .subscribe((_coupon) => {
        this.buttonStatus.coupon = true;
        this.couponButtonLabel = 'Aplicar';
        if (!_coupon) return;

        this.couponError = '';
        if (typeof _coupon === 'string') {
          const couponError = Constants.CouponErrors[_coupon];
          this.couponError = couponError || 'Cupón inválido';
          return;
        }
        if (_coupon.type !== CouponType.Code) return;

        this.codeCoupons.push(_coupon as ExplicitCouponType<CouponType.Code>);
        this.applyPrices(_coupon.discountToken);
      });
  }

  getErrorMessage(tixError?: OrderCreationError, isoCode?: string) {
    if (tixError) {
      return getOrderCreationErrorMessage(tixError);
    }

    let response;
    let recommendation;

    if (!isoCode) {
      isoCode = '-1';
    }

    if (Constants.TransactionErrors[isoCode]) {
      response = Constants.TransactionErrors[isoCode].response;
      recommendation = Constants.TransactionErrors[isoCode].recommendation;
    } else {
      response = Constants.TransactionErrors['-1'].response;
      recommendation = Constants.TransactionErrors['-1'].recommendation;
    }

    return {
      code: isoCode,
      response: `(${isoCode}) ${response}`,
      recommendation: recommendation.toUpperCase(),
    };
  }

  paymentError(error: CheckoutTixErrorResponse | string | ErrorInformation) {
    this._startTimer();

    this.buttonStatus.checkout = true;
    this.error = error;

    let tixError: OrderCreationError | undefined;
    let isoCode: string | undefined;

    if (typeof error === 'object' && 'tix_error' in error) {
      tixError = error.tix_error;
    }

    this._hideLoadingDialog(() => {
      if (!error) {
        error = '';
      }

      if (
        typeof error === 'object' &&
        'iso_code' in error &&
        !Constants.TransactionErrors[error!.iso_code]
      ) {
        error = error?.iso_code;
      }

      if (typeof error === 'object' && 'iso_code' in error) {
        isoCode = error?.iso_code || error.code;
      }

      let msg: any;
      if (typeof error === 'object' && 'recommendation' in error) {
        msg = error;
      } else {
        msg = this.getErrorMessage(tixError, isoCode);
      }

      //TODO: Define a better way to notify client about any error regarding order payment
      Swal.fire({
        title: `Declinada`,
        html:
          typeof error == 'string'
            ? error
            : `<div>
                <p class="mb-4">${msg?.code}</p>
                <p class="mb-4">${msg.response}</p>
                <p>
                 ${!tixError ? msg.recommendation : ''}
                </p>
              </div>`,
        icon: 'error',
        confirmButtonText: 'OK',
      });
    });
  }

  async proceedPayment() {
    if (this.isFormValid()) {
      this.stopTimer();

      this.buttonStatus.checkout = false;

      if (!this.loadingComponent?.isVisible()) {
        this.loadingComponent?.toggle(); // Show dialog
      }

      const discountTokens: NonEmptyDiscountTokens | undefined =
        this.discountTokens.length > 0
          ? (this.discountTokens as NonEmptyDiscountTokens)
          : undefined;

      const checkoutResponse = await this.checkoutSessionService.checkout({
        session_token: this.sessionToken,
        buyer_email: this.buyerData.email,
        buyer_full_name: this.buyerData.name,
        is_secured: this.paymentInfo.secured,
        promoter_slug: this.promoterSlug,
        discount_tokens: discountTokens,
        card_info: {
          cvc: this.paymentInfo.cvc,
          exp: this.paymentInfo.expDate,
          card: this.paymentInfo.card,
        },
      });

      this._onPaymentProceed(checkoutResponse);
    } else {
      alert('Complete datos personales y de pago.');
    }
  }

  /**
   * Validate checkout form
   * @return boolean
   * */
  isFormValid(): boolean {
    const paymentValidation = this.eventFree
      ? true
      : this.paymentInfo.name != '' &&
        this.paymentInfo.card != '' &&
        this.paymentInfo.cvc != '' &&
        this.paymentInfo.expDate != null;

    return (
      this.buyerData.name != '' &&
      this.buyerData.email != '' &&
      paymentValidation &&
      this.emailInput.valid
    );
  }

  private async handleCybersourceThreeDSSession(
    resp: any,
    paymentInfo?: any,
    order?: any
  ) {
    const { accessToken, deviceDataCollectionUrl, referenceId } =
      resp.consumerAuthenticationInformation;
    const onGettingResult = () => {
      this._hideLoadingDialog(() => {});
    };

    const data = await this.threedsCybersourceService.handleThreeDSSession({
      ddp: { accessToken, deviceDataCollectionUrl, referenceId },
      paymentInfo,
      order,
      paymentHistoryId: resp.payment_history_id,
      onGettingResult,
    });

    return data;
  }

  private async _onPaymentProceed(
    resp: CheckoutResponse,
    paymentInfo?: any,
    order?: any
  ): Promise<void> {
    if ('consumerAuthenticationInformation' in resp) {
      const data = await this.handleCybersourceThreeDSSession(
        resp,
        paymentInfo,
        order
      );

      if (!data) {
        this.paymentError('La orden no pudo ser completada');
        return;
      }

      if ('code' in data) {
        this.paymentError(data);
        return;
      }

      if (!this.order) {
        this.paymentError('La orden no pudo ser completada');
        return;
      }

      this.order.id = data.orderId;
      this.order.slug = data.slug;
      this.order.createdAt = data.createdAt;

      return this.handleEndSuccessPaymentSession(data.slug);
    }

    if (
      'ResponseMessage' in resp &&
      resp.ResponseMessage === '3D_SECURE_2_METHOD'
    ) {
      this.threeDSForm = this.sanitizer.bypassSecurityTrustHtml(
        resp.ThreeDSMethod.MethodForm
      );
      setTimeout(() => {
        const form = document.querySelector(
          '#tdsMmethodForm'
        ) as HTMLFormElement;
        form.submit();
      }, 300);
      const sid = resp.TermUrl.split('sid=')[1];
      const notificationListenerKey = `3ds_notification_${sid}`;
      this.utilService.getSocket().on(notificationListenerKey, (response) => {
        this.eventService
          .checkoutThreeds({
            data: {
              ...resp,
              notificationStatus: response.threeDSMethodData
                ? 'RECEIVED'
                : 'EXPECTED_BUT_NOT_RECEIVED',
            },
          })
          .subscribe({
            next: (resp) => this._onPaymentProceed(resp as CheckoutResponse),
            error: (err) => this.paymentError(err),
          });
        this.utilService.getSocket().removeListener(notificationListenerKey);
      });

      return;
    } else if (
      'ResponseMessage' in resp &&
      resp.ResponseMessage === '3D_SECURE_CHALLENGE'
    ) {
      const challengeData = resp.ThreeDSChallenge;

      this.threeChallengeModal.formConfig = {
        CReq: challengeData.CReq,
        TermUrl: resp.TermUrl,
        RedirectPostUrl: challengeData.RedirectPostUrl,
      };

      setTimeout(() => {
        this.loadingComponent.toggle();
        this.threeChallengeModal.threeDsChallengeBtn.nativeElement.click();
        this.threeChallengeModal.challengeForm.nativeElement.submit();
      }, 300);

      const sid = resp.TermUrl.split('sid=')[1];
      const notificationListenerKey = `3ds_notification_${sid}`;
      this.utilService.getSocket().on(notificationListenerKey, (response) => {
        this.loadingComponent.toggle();
        this.threeChallengeModal.threeDsChallengeBtn.nativeElement.click();
        this.eventService
          .checkoutThreeds({
            data: {
              ...resp,
              CRes: response.cres,
            },
          })
          .subscribe({
            next: (resp) =>
              this._onPaymentProceed(resp as CheckoutAzulResponse),
            error: (err) => this.paymentError(err),
          });
        this.utilService.getSocket().removeListener(notificationListenerKey);
      });
      return;
    }

    if (!('details' in resp)) {
      return this.paymentError(resp as CheckoutTixErrorResponse);
    }

    const newOrder = resp.data.order;
    resp.data.order;
    this.order = {
      buyerName: newOrder.buyer_full_name,
      buyerEmail: newOrder.buyer_email,
      totalToPay: newOrder.total,
      subTotal: newOrder.sub_total,
      serviceFee: newOrder.service_fee,
      insuranceFee: newOrder.insurance_fee,
      id: newOrder.id,
      createdAt: new Date(newOrder.created_at),
      slug: newOrder.slug,
    } as Order;

    return this.handleEndSuccessPaymentSession(newOrder.slug);
  }

  private handleEndSuccessPaymentSession(slug: string) {
    this._hideLoadingDialog(() => {
      if (this.countDownInterval != null) {
        clearInterval(this.countDownInterval as any);
        this.countDownInterval = null;
      }
      if (this.order) {
        this.order.totalToPay = this.paymentInfo.secured
          ? this.orderPrice.securedTotal
          : this.orderPrice.total;

        this.order.subTotal = this.orderPrice.orderAmount;
        this.order.serviceFee = this.orderPrice.fees.serviceFee;
        this.order.insuranceFee = this.paymentInfo.secured
          ? this.orderPrice.fees.insuranceFee
          : 0;
      }

      this.storageService.set(
        Constants.Keys.OrderConfirmation,
        JSON.stringify(this.order)
      );
      this._clearSession(false);

      this.router.navigate([`/order/${slug}/confirmation`]);
    });
  }

  /**
   * Hides the loading dialog.
   * This expression was put inside a particular method because a bug when the dialog is request to be closed.
   * It seems that if both events (show and hide) occur within a small difference of time, dismiss doesn't work
   * properly.
   *
   * The call to toggle function is wrapped inside a setTimeout call that takes 1 second to execute.
   * @param callback
   * @return void
   * */
  private _hideLoadingDialog(callback: Function): void {
    //TODO: Behavior bug should be fixed.
    if (this.loadingComponent?.isVisible()) {
      setTimeout(() => {
        this.loadingComponent?.toggle();
        setTimeout(() => {
          callback();
        }, 500);
      }, 500);
    } else {
      callback();
    }
  }

  private _setTicketSocketListener(): void {
    if (this.checkoutTimestamp == 0) {
      // Start timer (countdown)

      this.checkoutTimestamp = Date.now();

      const ids = this.order?.ticketIds ?? [];

      this.storageService.set(
        Constants.Keys.CheckoutTimeout,
        this.checkoutTimestamp.toString(),
        Constants.StorageType.Session
      );
      this.storageService.set(
        Constants.Keys.CheckoutTimeoutTicketHeld,
        ids.toString(),
        Constants.StorageType.Session
      );
      this._startTimer();
    } else {
      const ids = this.storageService.get(
        Constants.Keys.CheckoutTimeoutTicketHeld,
        Constants.StorageType.Session
      );
      if (ids != null) {
        this._startTimer();
      }
    }
  }

  private stopTimer() {
    if (this.countDownInterval) clearInterval(this.countDownInterval as any);
  }

  private _startTimer(): void {
    const _secondsStored = this.storageService.get(
      Constants.Keys.CheckoutTimeoutRemainingSeconds,
      Constants.StorageType.Session
    );
    // Start countdown timer
    let timerSeconds =
      _secondsStored != null
        ? parseInt(_secondsStored)
        : environment.orderCheckoutTimeoutInSeconds;

    this.timeoutMinutes = parseInt((timerSeconds / 60).toString());
    this.timeoutSeconds = timerSeconds - this.timeoutMinutes * 60;

    this.countDownInterval = setInterval(() => {
      timerSeconds--;
      this.storageService.set(
        Constants.Keys.CheckoutTimeoutRemainingSeconds,
        timerSeconds.toString(),
        Constants.StorageType.Session
      );

      if (this.timeoutSeconds == 0) {
        if (this.timeoutMinutes > 0) {
          this.timeoutMinutes--;
          this.timeoutSeconds = 59;
        } else {
          this.timeoutSeconds = 0;
        }
      } else {
        if (this.timeoutSeconds > 0) this.timeoutSeconds--;
      }

      if (timerSeconds == 0) {
        this._onTimerLimitReached();
      }
    }, 1000);
  }

  private _onTimerLimitReached(): void {
    this.checkoutSessionService.endSession(this.sessionToken);
    sessionStorage.removeItem(Constants.Keys.CheckoutSession);
    alert('Tiempo de espera para procesar orden excedido.');
    this.goBackToEvent();
  }

  handleCouponCodeChange($event: Event) {
    const target = $event.target as HTMLInputElement;

    this.couponCode = target.value.toUpperCase();
  }

  toggleSummary() {
    this.showSummary = !this.showSummary;
  }

  removeCardDiscount() {
    if (!(this.order && this.order.coupons)) return;

    const i = this.order.coupons.findIndex((c) => c.id == this.cardCoupon?.id);

    if (i == -1) return;

    this.order.coupons.splice(i, 1);
    this.cardCoupon = undefined;
  }

  async searchCardDiscounts() {
    if (this.paymentInfo.card.length !== 16) return;

    try {
      let coupon = await lastValueFrom(
        this.couponService.find(
          this.paymentInfo.card.slice(0, 6),
          this.event.id,
          [],
          CouponType.CreditCard
        )
      );

      if (typeof coupon == 'string' || !coupon) {
        this.cardCoupon && this.removeCardDiscount();
        return;
      } else {
        await this.applyPrices(coupon.discountToken);
      }

      this.cardCoupon;
      if (this.cardCoupon) {
        this.removeCardDiscount();
      }

      this.cardCoupon = coupon as ExplicitCouponType<CouponType.CreditCard>;
      this.discountTokens.push(coupon.discountToken);

      await Swal.fire({
        html: `Descuento (${coupon.name} -${coupon.discount}%) aplicado correctamente`,
        icon: 'success',
      });
    } catch (e) {
      console.log(e);
    }
  }

  private addStartOnDates(tts: TicketType[]) {
    this.dates = [];

    for (const tt of tts) {
      if (tt.start_on !== null && !this.dates.includes(tt.start_on)) {
        this.dates.push(tt.start_on);
      }
    }

    if (this.dates.length === 0 && this.event) {
      this.dates.push(this.event.startAt.toString());
    }

    this.dates.sort();
  }

  private goBackToEvent() {
    return this.router.navigate([
      `event/${this.route.snapshot.params['slug']}`,
    ]);
  }

  private async getPricesWithRetries(
    tries: number,
    products: Product[],
    discountTokens: string[]
  ) {
    while (tries > 0) {
      const resp = await this.getPrices(products, discountTokens);

      if (resp) {
        return resp;
      }

      tries--;
    }
    return null;
  }

  private getPrices(
    products: Product[],
    discountTokens: string[]
  ): Promise<OrderPrices | null> {
    return new Promise((resolve, reject) => {
      try {
        this.eventService
          .getOrderPrices(products, discountTokens)
          .subscribe((resp) => {
            resolve(resp);
          });
      } catch (error) {
        reject(error);
      }
    });
  }

  private async applyPrices(discountToken?: string) {
    if (this.eventFree) {
      return;
    }

    if (discountToken) {
      this.discountTokens.push(discountToken);
    }

    const orderFee = await this.getPricesWithRetries(
      Constants.RetriesN.CalcFees,
      this.products,
      this.discountTokens
    );

    if (!orderFee) {
      this.goBackToEvent();
      return;
    }

    this.orderPrice = orderFee;

    this.gateway = orderFee.gateway?.toUpperCase() || 'AZUL';
    this.insuranceFee = orderFee.fees.insuranceFee;

    this.calcTotal();
  }
}
