import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Product } from 'src/app/core/types/product.type';
import { lastValueFrom, take } from 'rxjs';
import Order from 'src/app/core/models/order';
import { OrderStatus } from 'src/app/core/enums/orderStatus.enum';
import { OrderCreationError } from 'src/app/core/enums/orderCreationError.enum';
import { CheckoutSessionCode } from 'src/app/core/enums/checkout-session-code.enum';

export type CheckoutSessionServiceBaseResponse<D, T> = {
  code: number;
  details: D;
  data: T;
};

export type CheckoutSessionStartResponse = CheckoutSessionServiceBaseResponse<
  { ticket_types_ids: number[] },
  { session_token: string; in_session_count_events: string[] }
>;

export type NonEmptyDiscountTokens = [string, ...string[]];

export type CheckoutSessionDto = {
  session_token: string;
  buyer_email: string;
  buyer_full_name: string;
  promoter_slug?: string;
  is_secured: boolean;
  discount_tokens?: NonEmptyDiscountTokens;
  card_info: {
    cvc: string;
    exp: string;
    card: string;
  };
};

export type CheckoutAzulSuccessResponse = {
  paymentId: number;
  TermUrl: string;
  MethodNotificationUrl: string;
  AuthorizationCode: string;
  ResponseMessage: string;
  ResponseCode: string;
  ThreeDSMethod: { MethodForm: string };
  ThreeDSChallenge: {
    CReq: string;
    MD: string;
    PaReq: string;
    RedirectPostUrl: string;
  };
  Ticket: string;
};

export type CheckoutAzulErrorResponse = {
  auth_code: string;
  response_code: string;
  response_message: string;
  response_error: string;
  iso_code: string;
};

export type CheckoutTixErrorResponse = {
  code: string;
  error: string;
  msg: string;
  iso_code: string;
  tix_error: OrderCreationError;
};

export type CheckoutAzulResponse =
  | CheckoutAzulErrorResponse
  | CheckoutAzulSuccessResponse;

export type CheckoutSessionErrorResponse = {
  code: CheckoutSessionCode;
};

export type OrderCreatedResponse = {
  code: number;
  details: {
    ticket_types_ids: number[];
  };
  data: {
    order: {
      id: number;
      buyer_full_name: string;
      buyer_email: string;
      status: OrderStatus;
      slug: string;
      total: number;
      sub_total: number;
      service_fee: number;
      insurance_fee: number;
      created_at: string;
      tickets: [{ id: number; status: string }];
    };
  };
};

export type CheckoutResponse =
  | CheckoutAzulResponse
  | OrderCreatedResponse
  | CheckoutTixErrorResponse;

@Injectable({
  providedIn: 'root',
})
export class CheckoutSessionService {
  constructor(private http: HttpClient) {}

  async startSession(
    eventId: number,
    products: Product[]
  ): Promise<CheckoutSessionStartResponse> {
    const req = this.http
      .post<CheckoutSessionStartResponse>(`${environment.apiUrl}/checkout`, {
        products,
        event_id: eventId,
      })
      .pipe(take(1));
    return lastValueFrom<CheckoutSessionStartResponse>(req);
  }

  async endSession(sessionToken: string): Promise<void> {
    const req = this.http
      .post(`${environment.apiUrl}/checkout/end`, {
        session_token: sessionToken,
      })
      .pipe(take(1));
    await lastValueFrom(req);
  }

  async checkout(dto: CheckoutSessionDto): Promise<CheckoutResponse> {
    const req = this.http
      .post<CheckoutAzulResponse>(`${environment.apiUrl}/orders`, {
        session_token: dto.session_token,
        discount_tokens: dto.discount_tokens,
        buyer_email: dto.buyer_email,
        buyer_full_name: dto.buyer_full_name,
        promoter_slug: dto.promoter_slug,
        is_secured: dto.is_secured,
        card_info: dto.card_info,
      })
      .pipe(take(1));
    return await lastValueFrom(req);
  }
}
