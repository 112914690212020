import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { DetailService } from '../../../pages/detail/services/detail.service';
import { Product } from 'src/app/core/types/product.type';

@Component({
  selector: 'unavailable-button',
  templateUrl: './unavailable-button.component.html',
  styleUrls: ['./unavailable-button.component.css'],
})
export class UnavailableButtonComponent
  implements OnChanges, OnDestroy, OnInit
{
  @Input() soldOut = false;
  @Input() holdedOut = false;
  @Input() product!: Product;

  @Output()
  productEventEmitter = new EventEmitter<Product>();

  //5 minutes
  time = 300000;

  interval: any;

  constructor(private detailService: DetailService) {}

  ngOnInit(): void {
    this.product.qty = 0;
    this.productEventEmitter.emit(this.product);
  }

  ngOnChanges() {
    if (this.holdedOut && !this.interval) {
      this.interval = setInterval(() => {
        if (this.time) {
          this.time -= 1000;
        } else {
          clearInterval(this.interval);
          this.detailService.refreshTicketTypes();
        }
      }, 1000);
    }
  }

  ngOnDestroy() {
    clearInterval(this.interval);
  }

  get timer() {
    return this.millisToMinutesAndSeconds(this.time);
  }

  millisToMinutesAndSeconds(millis: number) {
    const minutes = Math.floor(millis / 60000);
    const seconds = parseInt(((millis % 60000) / 1000).toFixed(0));
    return minutes + ':' + (seconds < 10 ? '0' : '') + seconds;
  }
}
