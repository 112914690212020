import { CybersourceThreeDSStatus } from '../enums/cybersourceThreeDSStatus.enum';

export class Constants {
  static Keys = {
    Event: '@event',
    EventDetail: '@event_detail',
    EventOrder: '@event_order',
    OrderConfirmation: '@order_confirmation',
    OrderDetails: '@order_details',
    CheckoutTimeout: '@checkout_timeout',
    CheckoutTimeoutRemainingSeconds: '@checkout_timeout_remaining_seconds',
    CheckoutTimeoutTicketHeld: '@checkout_timeout_ticket_held',
    Promoter: '@promoter',
    ListScrollPosition: '@listScrollPosition',
    CheckoutSession: '@checkoutSession',
  };

  static defaultRecommendation =
    'intente nuevamente con otra tarjeta o contacte a su banco';

  static StorageType = {
    Session: 'session',
    Local: 'local',
  };

  static RetriesN = {
    CalcFees: 3,
  };

  static CybersourcePaymentError(error: CybersourceThreeDSStatus) {
    const checkAgainRecommendation =
      'Revisar datos colocados en la solicitud de pago.';
    const timeoutRecommendation =
      'Realizar solicitud de pago nuevamente. Se ha excedido el tiempo permitido.';
    const invalidDataRecommendation =
      'Solicite una tarjeta de pago diferente u otra forma de pago.';

    switch (error) {
      case CybersourceThreeDSStatus.MISSING_FIELD:
        return {
          response: 'Rechazada. A la solicitud le faltan uno o más campos.',
          recommendation: checkAgainRecommendation,
        };
      case CybersourceThreeDSStatus.CANCELLED:
        return {
          response: 'La orden fue rechazada.',
          recommendation: 'Contacte a atención al cliente de su banco emisor.',
        };
      case CybersourceThreeDSStatus.DECLINED:
        return {
          response: 'La orden fue rechazada por Decision Manager.',
          recommendation: 'Contacte a atención al cliente de su banco emisor.',
        };
      case CybersourceThreeDSStatus.INVALID_ACCOUNT_NOT_ALLOWED_CREDIT_PAYMENT_ID:
        return {
          response:
            'El número de cuenta pasó la verificación porDecision Manager.',
          recommendation: 'Corrija y reenvíe la solicitude subanco emisor.',
        };
      case CybersourceThreeDSStatus.INVALID_AMOUNT:
        return {
          response: 'Fondos insuficientes',
          recommendation: this.defaultRecommendation,
        };
      case CybersourceThreeDSStatus.INSUFFICIENT_FUND:
        return {
          response: 'Fondos insuficientes',
          recommendation: this.defaultRecommendation,
        };
      case CybersourceThreeDSStatus.INVALID_CARD:
        return {
          response: 'Rechazo. Número de cuenta no válido.',
          recommendation: invalidDataRecommendation,
        };
      case CybersourceThreeDSStatus.CARD_TYPE_NOT_ACCEPTED:
        return {
          response: 'No aceptamos este tipo de tarjetas',
          recommendation: invalidDataRecommendation,
        };
      case CybersourceThreeDSStatus.INVALID_CARD_TYPE:
        return {
          response: 'Tarjeta no válida',
          recommendation: invalidDataRecommendation,
        };
      case CybersourceThreeDSStatus.INVALID_DATA:
        return {
          response:
            'Rechazada. uno o más campos de la solicitud contienen datos no válidos.',
          recommendation: checkAgainRecommendation,
        };
      case CybersourceThreeDSStatus.SERVER_TIMEOUT:
        return {
          response: 'El servidor agotó el tiempo de espera',
          recommendation: timeoutRecommendation,
        };
      case CybersourceThreeDSStatus.SERVICE_TIMEOUT:
        return {
          response: 'El servicio agotó el tiempo de espera',
          recommendation: timeoutRecommendation,
        };
      case CybersourceThreeDSStatus.SYSTEM_ERROR:
        return {
          response: 'No se pudo crear la orden',
          recommendation: 'Intente luego de unos minutos',
        };
      default:
        return {
          response: 'no se pudo crear la orden',
          recommendation: 'intente nuevamente',
        };
    }
  }

  static TransactionErrors: {
    [isoCode: string]: { response: string; recommendation: string };
  } = {
    '-1': {
      response: 'no se pudo crear la orden',
      recommendation: 'intente nuevamente',
    },
    3: {
      response: 'config. inválida',
      recommendation: 'intente nuevamente',
    },
    4: {
      response: 'tarjeta cancelada',
      recommendation: this.defaultRecommendation,
    },
    5: {
      response: 'declinada',
      recommendation: this.defaultRecommendation,
    },
    7: {
      response: 'tarjeta cancelada',
      recommendation: this.defaultRecommendation,
    },
    12: {
      response: 'trans inválida',
      recommendation: this.defaultRecommendation,
    },
    14: {
      response: 'tarjeta inválida',
      recommendation: 'favor ingresar correctamente los datos de la tarjeta',
    },
    41: {
      response: 'tarjeta perdida',
      recommendation: this.defaultRecommendation,
    },
    43: {
      response: 'tarjeta cancelada',
      recommendation: this.defaultRecommendation,
    },
    46: {
      response: 'tarjeta cancelada',
      recommendation: this.defaultRecommendation,
    },
    51: {
      response: 'insuf. fondos',
      recommendation: this.defaultRecommendation,
    },
    54: {
      response: 'tarjeta vencida',
      recommendation: this.defaultRecommendation,
    },
    59: {
      response:
        'Rechazo general de la tarjeta. No se proporciona ninguna otra información por parte del banco emisor.',
      recommendation: this.defaultRecommendation,
    },
    61: {
      response: 'declinada',
      recommendation: this.defaultRecommendation,
    },
    62: {
      response: 'declinada',
      recommendation: this.defaultRecommendation,
    },
    63: {
      response: 'declinada',
      recommendation: this.defaultRecommendation,
    },
    82: {
      response: 'declinada',
      recommendation: this.defaultRecommendation,
    },
    83: {
      response: 'motivos de seguridad',
      recommendation: this.defaultRecommendation,
    },
    91: {
      response: 'emisor no disponible',
      recommendation: 'intente nuevamente o contacte a su banco',
    },
    99: {
      response: 'declinada',
      recommendation: 'intente nuevamente o contacte a su banco',
    },
    '3D2METHOD': {
      response: 'declinada',
      recommendation: this.defaultRecommendation,
    },
    '3D': {
      response: 'declinada',
      recommendation: this.defaultRecommendation,
    },
    MLN: {
      response: 'declinada',
      recommendation: 'el nombre del titular es incorrecto',
    },
  };

  static CouponErrors = {
    'coupon expired': 'Cupón expirado',
    'coupon max limit': 'Cupón agotado',
  };
}
