<header
  class="py-3 position-relative sticky-top position-sticky bg-light"
  [class]="{ showSearch: showSearch }"
>
  <div class="container-fluid">
    <div class="row justify-content-between align-items-center">
      <div class="col-auto">
        <a
          (click)="goToHome()"
          class="d-flex align-items-center text-decoration-none gap-2"
          style="cursor: pointer"
        >
          <figure class="header_brand">
            <img
              src="assets/img/logo-tix.png"
              alt="Tix Eventos"
              class="header_logo"
            />
          </figure>
        </a>
      </div>
      <div class="col-auto order-md-last">
        <nav class="right_nav d-none d-md-block">
          <ul class="nav">
            <li class="nav-item">
              <a
                [href]="environment.vendorsUrl"
                class="btn btn-outline-dark px-4 py-2 rounded-pill fw-semibold"
                >Mi cuenta</a
              >
            </li>
          </ul>
        </nav>
        <button
          (click)="toggleSearch()"
          class="btn_mobile rounded-circle btn btn-sm btn-white p-0 text-center shadow-0 d-block d-md-none"
          style="width: 55px; height: 55px"
        >
          <span class="material-symbols-outlined">
            {{ searchIconName }}
          </span>
        </button>
      </div>
      <div class="col-auto order-first order-md-2">
        <button
          class="btn_mobile rounded-circle btn btn-sm btn-white p-0 text-center shadow-0 d-block d-md-none navbar-toggler collapse"
          style="width: 55px; height: 55px"
          type="button"
          (click)="toggleNav()"
        >
          <span class="material-symbols-outlined">
            {{ showNav ? 'close' : 'segment' }}
          </span>
        </button>
        <nav
          class="primary_nav d-md-block"
          id="primaryNavHeader"
          [class]="{ showNav: showNav }"
        >
          <ul class="nav d-flex fw-semibold">
            <li class="nav-item" *ngFor="let item of items">
              <a
                class="nav-link link-dark"
                (click)="goTo(item)"
                style="cursor: pointer"
                >{{ item.label }}</a
              >
            </li>
            <li class="nav-item d-block d-md-none">
              <a
                [href]="environment.vendorsUrl"
                class="btn btn-outline-dark px-4 py-2 rounded-pill fw-semibold"
                >Mi cuenta</a
              >
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
  <div class="search-container position-relative d-md-none" *ngIf="showSearch">
    <div class="container-fluid mt-2">
      <div class="row">
        <div class="col">
          <input
            type="text"
            id="vacano"
            #searchInput
            class="form-control rounded-pill border-dark"
            placeholder="Buscar evento"
            [(ngModel)]="searchCriteria"
            (keyup)="onSearchEvent($event)"
          />
        </div>
      </div>
    </div>
    <div
      class="search-result container-fluid position-absolute bg-white d-grid gap-2 py-3 z-3 mt-1 rounded-bottom-4"
    >
      <ng-container *ngIf="searchCriteria">
        <app-event-item
          *ngFor="let event of events"
          [event]="event"
          (click)="closeSearch()"
        ></app-event-item>
      </ng-container>
    </div>
  </div>
</header>
<div
  class="bg-search-result bg-opacity-25 position-fixed bg-black"
  *ngIf="showSearch"
></div>
