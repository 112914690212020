import { Injectable } from '@angular/core';
import { BaseHttpService } from '../../../core/services/base.http.service';
import { HttpClient } from '@angular/common/http';
import { catchError, map, Observable, of } from 'rxjs';
import OrderDetails, {
  EventData,
  TicketData,
} from '../../../core/models/order.details';
import Order from '../../../core/models/order';
import CouponType from '../../../core/enums/couponType.enum';

@Injectable({
  providedIn: 'root',
})
export class OrderService extends BaseHttpService {
  constructor(http: HttpClient) {
    super(http);
  }

  getDetail(slug: string): Observable<OrderDetails | null> {
    const url = `${this.getApiBaseUrl()}/order/${slug}/details`;

    return this.http.get(url).pipe(
      map((resp: any) => {
        const _event: EventData = {
          id: resp.event.id,
          name: resp.event.name,
          ticketsComments: resp.event.tickets_comments,
          startAt: resp.event.start_at,
          place: resp.event.place,
          link: resp.event.link,
          cover: resp.event.cover,
        };

        const order = {
          id: resp.id,
          buyerName: resp.buyer_full_name,
          buyerEmail: resp.buyer_email,
          createdAt: resp.createdAt,
          slug: resp.slug,
          event: _event,
          tickets: this.parseTickets(resp.tickets),
          cancelledTickets: this.parseTickets(resp.cancelled_tickets),
          status: resp.status,
          insurance_fee: resp.insurance_fee,
          insuranceFee: resp.insurance_fee,
          free_event: resp.free_event,
          serviceFee: resp.service_fee,
          total: resp.total,
          totalToPay: resp.total,
          sub_total: resp.sub_total,
          subTotal: resp.sub_total,
          discounted: resp.discounted,
          discounts: this.parseDiscounts(resp.discounts),
          authorization: resp.payment_history
            ? resp.payment_history?.auth_code
            : '',
          creditCard: resp.payment_history ? resp.payment_history?.card : '',
          cardType: resp.payment_history ? resp.payment_history?.card_type : '',
        };

        return order;
      }),
      catchError((e) => {
        console.log(e);
        return of(null);
      })
    );
  }

  private parseDiscounts(discounts: any[]) {
    if (!discounts) {
      return [];
    }
    return discounts.map((d) => {
      const parsedDiscount = {
        discount: d.discount,
        discount_code: d.discount_code,
        discounted: d.discounted,
      } as any;

      if (d.coupon) {
        parsedDiscount.coupon = d.coupon;
      }

      return parsedDiscount;
    });
  }

  private parseTickets(tickets: any) {
    const result: TicketData[] = [];

    tickets.forEach((ticket: any) => {
      result.push({
        id: ticket.id,
        label: ticket.label,
        checkInDate: ticket.check_in_at,
        ticketType: ticket.ticket_type,
        price: ticket.price,
        gift: ticket?.gift,
        isSeat: ticket.ticket_type.isSeat,
        qrcode: ticket.qrcode,
      });
    });

    return result;
  }

  codeCouponsDiscount(order: Order | null) {
    let result = 0;

    if (!order?.coupons) return result;

    let couponDiscount = 0;
    let ticketTypesDiscount = 0;

    order.coupons.forEach((c) => {
      if (c.type == CouponType.CreditCard) return;

      if (c.ticket_types?.length) {
        let totalSum = 0;

        c.ticket_types.forEach((tt) => {
          const ticket = order!.tickets.find((t) => t.id === tt.id);

          if (ticket) {
            const priceSum = ticket.tickets[0].price * ticket.tickets.length;

            totalSum += priceSum;
          }
        });

        ticketTypesDiscount += totalSum * (c.discount / 100);
        c.discounted = ticketTypesDiscount;
      } else {
        if (c.discount > 100) {
          couponDiscount += 1;
        } else {
          couponDiscount += c.discount / 100;
        }

        c.discounted = (c.discount / 100) * order.subTotal;
      }
    });

    result = Math.abs(order.subTotal * couponDiscount - ticketTypesDiscount);

    if (result > order.subTotal) result = order.subTotal;
    return result;
  }

  getClientAddress() {
    return this.http.get('https://geolocation-db.com/json/');
  }
}
