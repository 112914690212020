import {
  AfterViewInit,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
} from '@angular/core';
import Event from '../../../../core/models/event';
import Category from '../../../../core/models/category';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { CarouselService } from '../../services/carousel.service';
import Banner from '../../../../core/models/banner';
import { DashboardService } from '../../services/dashboard.service';
import { StorageService } from 'src/app/core/services/storage.service';
import { Constants } from 'src/app/core/utils/constants';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css'],
})
export class ListComponent implements OnInit, AfterViewInit, OnDestroy {
  @HostListener('window:scroll')
  handleScroll() {
    const yscroll = window.scrollY;

    this.storageService.set(
      Constants.Keys.ListScrollPosition,
      yscroll.toString(),
      Constants.StorageType.Session
    );
  }

  placeholders = Array(8).fill(0);

  public categorySelected: string = 'Todos'; // Default 'Todos'
  public categoryHash: string = 'all'; // Filter hash

  public events: Event[] = [];

  private get _events() {
    return this.dashboardService._events;
  }

  get loading() {
    return this.dashboardService.loading;
  }

  public banners: Banner[] = [];
  public advertings: Banner[] = [];

  private _eventsSubscription: Subscription | null = null;
  private _bannersSubscription: Subscription | null = null;

  constructor(
    private storageService: StorageService,
    private route: ActivatedRoute,
    private carouselService: CarouselService,
    private dashboardService: DashboardService
  ) {}

  ngAfterViewInit(): void {
    const scrollY = this.storageService.get(
      Constants.Keys.ListScrollPosition,
      Constants.StorageType.Session
    );
    if (scrollY) {
      window.scroll({
        top: Number(scrollY) - 588,
        left: 0,
        behavior: 'instant' as ScrollBehavior,
      });
    }
  }

  ngOnInit(): void {
    this.categoryHash = this.route.snapshot.params['category_hash'];
    this._eventsSubscription = this.dashboardService.fetched.subscribe(() => {
      this.events = this.dashboardService._events;
    });

    this._bannersSubscription = this.carouselService
      .getBanners()
      .subscribe((resp: Banner[]) => {
        for (let i = 0; i < resp.length; i++) {
          const banner = resp[i];

          if (!banner.active) continue;

          if (banner.show_as_advertising) {
            this.advertings.push(banner);
          } else {
            this.banners.push(banner);
          }
        }
      });
  }

  ngOnDestroy(): void {
    this._eventsSubscription?.unsubscribe();
    this._bannersSubscription?.unsubscribe();
  }

  onEventCategoryChanged(category: Category): void {
    this.categorySelected = category.name;
  }

  onQueryCategory(query: string = ''): void {
    if (query.trim().length > 0) {
      this.events = this._events.filter((event: Event) =>
        event.name.toLowerCase().trim().includes(query.toLowerCase().trim())
      );
    } else {
      this.events = this._events;
    }
  }
}
