import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import OrderDetails, {
  TicketData,
} from '../../../../core/models/order.details';
import { OrderTicketData } from '../../components/order-ticket/order-ticket.component';
// @ts-ignore
import * as html2pdf from 'html2pdf.js';
import getMobileOS from '../../../../core/functions/getMobileOS';
import MobileOs from '../../../../core/enums/mobile-os';

@Component({
  selector: 'app-order-tickets',
  templateUrl: './order-tickets.component.html',
  styleUrls: ['./order-tickets.component.css'],
})
export class OrderTicketsComponent {
  @ViewChild('printSection') printSection!: ElementRef;

  @Input() order: OrderDetails | null = null;

  @Input() page = 1;
  @Input() totalPages = 1;
  @Input() pageTickets: TicketData[] = [];

  @Output() loading = new EventEmitter();

  public printingDate: Date = new Date();

  constructor() {}

  async onClickDownload() {
    try {
      let tickets: HTMLElement[] = Array.from(
        this.printSection.nativeElement.children
      );

      const os = getMobileOS();

      let worker = html2pdf()
        .set({
          filename: os == MobileOs.IOS ? 'Tickets' : tickets[0].id,
          image: {
            quality: 1,
            type: 'webp',
          },
          html2canvas: {
            allowTaint: true,
            useCORS: true,
            scale: 1.5,
          },
        })
        .from(tickets[0])
        .toContainer()
        .toCanvas()
        .toPdf();

      if (os == MobileOs.IOS) {
        for (let i = 1; i < tickets.length; i++) {
          const t = tickets[i];

          worker = worker
            .get('pdf')
            .then((pdf: any) => {
              pdf.addPage();
            })
            .from(t)
            .toContainer()
            .toCanvas()
            .toPdf();
        }

        const pdf = await worker.get('pdf');

        pdf.save('Tickets');
      } else {
        worker.save();

        await new Promise((resolve) => setTimeout(resolve, 1000));

        for (let i = 1; i < tickets.length; i++) {
          const t = tickets[i];

          html2pdf()
            .set({
              filename: t.id,
              image: {
                quality: 1,
                type: 'webp',
              },
              html2canvas: {
                allowTaint: true,
                useCORS: true,
                scale: 1.5,
              },
            })
            .from(t)
            .toContainer()
            .toCanvas()
            .toPdf()
            .save();

          await new Promise((resolve) => setTimeout(resolve, 1000));
        }
      }
    } catch (e) {
      console.log(e);
    }
  }

  prepareData(ticket: TicketData): OrderTicketData {
    return {
      eventDate: this.order?.event?.startAt ?? new Date(),
      eventName: this.order?.event?.name ?? '',
      buyerName: this.order?.buyerName ?? '',
      buyerEmail: this.order?.buyerEmail ?? '',
      eventPlace: this.order?.event?.place ?? '',
      orderId: this.order?.id ?? 0,
      printingDate: this.printingDate,
      ticketId: ticket.id,
      qrSource: ticket.qrcode,
      ticketStatus: ticket.checkInDate === null ? 'Disponible' : 'Usado',
      ticketLabel: ticket.isSeat ? ticket.label : undefined,
      ticketType: {
        data: { attributes: ticket.ticketType, id: ticket.ticketType.id },
      },
    };
  }
}
