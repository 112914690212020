import { Component, Input } from '@angular/core';
import { StorageService } from '../../../../core/services/storage.service';
import { UtilService } from '../../../../core/services/util.service';
import Event from '../../../../core/models/event';

@Component({
  selector: 'event-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.css'],
})
export class CardComponent {
  @Input() event: Event | null = null;

  constructor(
    private localStorageService: StorageService,
    private utilService: UtilService
  ) {}

  getEventUrl(): string {
    return this.event ? `/event/${this.event.slug}` : '#';
  }

  getImageUrl(): string {
    return this.utilService.getAbsoluteUrl(this.event?.coverUri ?? '');
  }
}
