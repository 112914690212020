<article class="p-2 p-md-4 w-100 ticket_item">
  <div
    class="card border-dark shadow-0 mx-auto"
    style="min-height: 355px; max-width: 340px"
  >
    <div
      class="card-header border-bottom-0 text-center p-0 overflow-hidden bg-black d-flex align-items-center justify-content-center"
      style="height: 50px"
    >
      <img
        [src]="ticketsHeader"
        alt="header"
        style="width: 48px; height: 48px"
      />
    </div>
    <div class="card-body h-100 align-content-center">
      <div class="container-fluid p-0 h-100">
        <div class="row align-items-center d-print-block h-100">
          <div class="col-12 h-100 g-0">
            <div
              class="d-block h-100 d-md-grid d-print-grid align-items-center"
            >
              <ul class="list-group h-auto list-group-flush w-100 mb-3">
                <li class="list-group-item">
                  <div
                    class="d-flex w-100 justify-content-between align-items-top"
                  >
                    <div class="small">
                      <small class="m-0 fw-bold small text-uppercase"
                        >Ticket:</small
                      >
                      <h2 class="m-0 fw-bold h3" class="">
                        {{ data?.ticketId }}
                      </h2>
                    </div>
                    <div class="text-end">
                      <small class="m-0 fw-bold small d-block">Orden</small>
                      <small class="">TIX-{{ data?.orderId }}</small>
                    </div>
                  </div>
                </li>
                <li class="list-group-item">
                  <div class="w-100 align-items-top">
                    <small class="m-0 fw-bold small d-block">Nombre</small>
                    <small class="">{{ data?.buyerName }}</small>
                    <br />
                    <small class="">{{ data?.buyerEmail }}</small>
                  </div>
                </li>
                <li class="list-group-item">
                  <div class="w-100 align-items-top">
                    <small class="m-0 fw-bold small d-block">Evento</small>
                    <small class="">{{ data?.eventName }}</small>
                  </div>
                </li>
                <li class="list-group-item d-flex justify-content-between">
                  <div class="w-100 align-items-top">
                    <small class="m-0 fw-bold small d-block">Ubicación</small>
                    <small class="">{{ place }}</small>
                  </div>
                  <div class="w-100 align-items-top">
                    <small class="m-0 fw-bold small d-block">Fecha</small>
                    <small class="">{{
                      date | dateFormat: 'D MMM y  h:mm a'
                    }}</small>
                  </div>
                </li>
                <li class="list-group-item d-flex justify-content-between">
                  <div class="w-100 align-items-top">
                    <small class="m-0 fw-bold small d-block">Ticket</small>
                    <small class="">{{
                      data?.ticketType?.data?.attributes?.name
                    }}</small>
                  </div>
                  <div
                    class="w-100 align-items-top"
                    *ngIf="(data?.ticketLabel ?? null) !== null"
                  >
                    <small class="m-0 fw-bold small d-block">Asiento</small>
                    <small class="">{{ data?.ticketLabel }}</small>
                  </div>
                </li>
              </ul>
              <figure
                class="mb-0 mx-auto text-center d-grid rounded content-qr"
                style="
                  max-width: 300px;
                  width: fit-content;
                  border: 10px solid #000;
                "
              >
                <qrcode
                  [qrdata]="data?.qrSource || qrSource"
                  [width]="200"
                  [errorCorrectionLevel]="'M'"
                  [allowEmptyString]="true"
                ></qrcode>
                <div class="small mt-2 text-center mx-auto w-auto">
                  <small>{{
                    data?.printingDate | date: 'MMM d, y h:mm a'
                  }}</small>
                </div>
              </figure>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</article>
