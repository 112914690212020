<header class="bg-black p-0 text-center">
  <img src="assets/img/logo-tix.png" width="60px" alt="" />
</header>

<app-not-available
  *ngIf="invalidOrder; else content"
  [message]="'Opss!... Esta orden no existe'"
></app-not-available>

<ng-template #content>
  <order-detail-headband
    [loading]="loadingOrderDetail"
    [order]="order ?? null"
  ></order-detail-headband>

  <hr class="border my-4" />

  <app-order-skeleton *ngIf="loadingOrderDetail"></app-order-skeleton>
  <div
    *ngIf="!loadingOrderDetail"
    class="container-fluid my-3"
    style="max-width: 990px"
  >
    <div class="row">
      <div class="col-12 col-md-8 order-md-2">
        <order-detail-tickets [order]="order"></order-detail-tickets>
        <button
          *ngIf="!orderCancelled"
          class="btn btn-outline-dark w-100 rounded-pill mb-4"
          data-bs-toggle="modal"
          data-bs-target="#all_tickets"
          [disabled]="(order?.tickets ?? []).length == 0"
        >
          Ver todos los tickets
        </button>
        <div
          *ngIf="getComments().length > 0"
          class="card border-0 shadow-sm rounded-tix small mb-4"
        >
          <div
            class="card-header bg-white border-bottom rounded-tix py-3"
            style="
              border-bottom-left-radius: 0 !important;
              border-bottom-right-radius: 0 !important;
            "
          >
            <h6 class="m-0">Información adicional</h6>
          </div>
          <ul class="list-group rounded-0 border-0 rounded-tix text-dark">
            <li
              class="list-group-item border-0 border-bottom text-muted d-flex justify-content-between align-items-center py-3"
              *ngFor="let comment of getComments()"
            >
              <div class="">{{ comment }}</div>
            </li>
          </ul>
        </div>
      </div>

      <div class="col order-md-1">
        <div
          class="card border-0 rounded rounded-pill shadow-sm bg-transparent rounded-tix small"
          style="overflow: hidden"
        >
          <div
            class="card-header bg-white border-bottom rounded-tix py-3"
            style="
              border-bottom-left-radius: 0 !important;
              border-bottom-right-radius: 0 !important;
            "
          >
            <h6 class="m-0">Información de la orden</h6>
          </div>
          <div class="card-body p-0">
            <ul class="list-group rounded-0 border-0">
              <li class="list-group-item border-0 border-bottom text-muted">
                <strong>ID de la orden: </strong><br />
                TIX-{{ order?.id }}
              </li>
              <li class="list-group-item border-0 border-bottom text-muted">
                <strong>Nombre: </strong><br />
                {{ order?.buyerName }}
              </li>
              <li class="list-group-item border-0 border-bottom text-muted">
                <strong>Correo: </strong><br />
                {{ order?.buyerEmail }}
              </li>
              <ng-container *ngIf="!order?.free_event">
                <li class="list-group-item border-0 border-bottom text-muted">
                  <strong>Subtotal: </strong><br />
                  RD${{ order?.sub_total | number: '1.2-2' }}
                </li>
                <li
                  class="list-group-item border-0 border-bottom text-muted"
                  *ngIf="cardDiscount"
                >
                  <strong>
                    <span>Descuento ({{ cardDiscount?.coupon?.name }}</span>
                    <span> -{{ cardDiscount?.discount }}%)</span> </strong
                  ><br />
                  -RD${{ cardDiscount?.discounted || 0 | number: '1.2-2' }}
                </li>
                <li
                  class="list-group-item border-0 border-bottom text-muted"
                  *ngFor="let discount of codeDiscounts"
                >
                  <strong
                    >Descuento: ({{
                      discount.coupon.type === 'credit_card'
                        ? discount.coupon.name
                        : discount.discount_code
                    }}
                    -{{ discount!.discount }}%)</strong
                  ><br />
                  -RD${{ discount?.discounted || 0 | number: '1.2-2' }}
                </li>
                <li class="list-group-item border-0 border-bottom text-muted">
                  <strong>Cargo por servicio: </strong><br />
                  RD${{ order?.serviceFee | number: '1.2-2' }}
                </li>
                <li class="list-group-item border-0 border-bottom text-muted">
                  <strong>Cargo por seguro: </strong><br />
                  RD${{ order?.insurance_fee | number: '1.2-2' }}
                </li>
                <li class="list-group-item border-0 border-bottom text-muted">
                  <strong>Total: </strong><br />
                  RD${{ order?.total | number: '1.2-2' }}
                </li>
                <li class="list-group-item border-0 border-bottom text-muted">
                  <strong>Autorización: </strong><br />
                  {{ order?.authorization }}
                </li>
                <li class="list-group-item border-0 border-bottom text-muted">
                  <strong>Tarjeta: </strong><br />
                  <img
                    [src]="'/assets/img/type-' + order?.cardType + '-card.png'"
                    alt="card type"
                    style="width: 36px; height: 22px; object-fit: contain"
                    class="mx-2"
                  />
                  {{ order?.creditCard?.slice(6) }}
                </li>
              </ng-container>
              <li class="list-group-item border-0 border-bottom text-muted">
                <strong>Fecha de compra</strong>:<br />
                {{ order?.createdAt | dateFormat }}
              </li>
              <li
                class="list-group-item border-0 border-bottom text-muted"
                *ngIf="order?.insurance_fee"
              >
                <span class="badge text-bg-success">Orden asegurada</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<core-loading [visible]="isLoading"></core-loading>

<!-- Modal dialog to display all tickets -->
<div
  class="modal fade"
  id="all_tickets"
  tabindex="-1"
  aria-labelledby="all_tickets_label"
  aria-hidden="true"
>
  <div
    class="modal-dialog modal-xl modal-dialog-centered modal-fullscreen modal-dialog-scrollable"
  >
    <div class="modal-content">
      <div class="modal-header d-print-none">
        <h1
          class="modal-title fs-5 d-flex align-items-center"
          id="all_tickets_label"
        >
          <span
            class="material-symbols-outlined p-0 m-0"
            style="font-size: 24px"
            >qr_code_scanner</span
          >
          Tickets
        </h1>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body overflow-scroll">
        <app-order-tickets
          [pageTickets]="pageTickets"
          [page]="page"
          [totalPages]="totalPages"
          (loading)="handleLoad()"
          [order]="order"
        ></app-order-tickets>
      </div>
      <div
        class="modal-footer justify-content-between align-items-center d-flex"
      >
        <div class="w-25">
          {{ pageTickets.length }}/{{ order?.tickets?.length }} tickets
        </div>
        <nav class="">
          <ul class="pagination m-0">
            <li class="page-item">
              <button
                (click)="prevPage()"
                [disabled]="page <= 1"
                class="page-link"
                aria-label="Previous"
              >
                <span aria-hidden="true">&laquo;</span>
              </button>
            </li>
            <li class="page-item">
              <div class="page-link">{{ page }}/{{ totalPages }}</div>
            </li>
            <li class="page-item">
              <button
                (click)="nextPage()"
                [disabled]="page >= totalPages"
                class="page-link"
                aria-label="Next"
              >
                <span aria-hidden="true">&raquo;</span>
              </button>
            </li>
          </ul>
        </nav>
        <div class="w-25 d-flex justify-content-end">
          <button
            [disabled]="isLoading"
            (click)="downloadTickets()"
            class="bg-black btn-lg btn-dark btn d-flex align-items-center gap-2 justify-content-center"
          >
            <span class="material-symbols-outlined">picture_as_pdf</span>
            <span class="d-none d-md-block">Descargar PDF</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
