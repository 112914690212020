import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import Event from '../../../../core/models/event';
import { FormBuilder, Validators } from '@angular/forms';
import { VendorService } from '../../services/vendor.service';
import VendorMessageDto from '../../../../core/dtos/vendor-message.dto';
import { LoadingComponent } from '../../../../core/components/loading/loading.component';
import Swal from 'sweetalert2';

@Component({
  selector: 'event-vendor',
  templateUrl: './vendor.component.html',
  styleUrls: ['./vendor.component.css'],
})
export class VendorComponent {
  @ViewChild(LoadingComponent) loadingComponent!: LoadingComponent;
  @ViewChild('close_modal') closeModalBtn!: ElementRef<HTMLButtonElement>;

  @Input() public event: Event | null = null;

  public returnPolicyMessage: string =
    'Para cambios, devoluciones, comprobantes fiscales y cualquier solicitud relacionada al evento, favor enviar un mensaje al organizador.';

  sendMessageForm = this.fb.group({
    fullName: ['', Validators.required],
    email: ['', [Validators.email, Validators.required]],
    phone: ['', Validators.required],
    comment: ['', Validators.required],
  });

  get profileImage() {
    let result = this.event?.vendor.profilePictureUri;

    if (!result) result = './assets/img/user_avatar.png';

    return result;
  }

  get emailInvalid() {
    const control = this.sendMessageForm.controls.email;

    const result = control.errors && control.errors['email'];

    return result;
  }

  constructor(
    private fb: FormBuilder,
    private vendorsService: VendorService
  ) {}

  handleSubmit() {
    this.loadingComponent.toggle();

    const body = this.sendMessageForm.value as VendorMessageDto;

    this.vendorsService
      .sendMessageToVendor(this.event!.vendor.id, body)
      .subscribe({
        next: (response) => {
          setTimeout(() => {
            this.loadingComponent.toggle();

            Swal.fire({
              html: `Mensaje enviado!`,
              icon: 'success',
            });
          }, 700);
        },
        error: (err) => {
          alert('Ha ocurrido un error: ' + err);
        },
        complete: () => {
          this.closeModal();
        },
      });
  }

  closeModal() {
    this.closeModalBtn.nativeElement.click();
  }
}
